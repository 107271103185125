import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  pointer: {
    cursor: 'pointer'
  },
}));

const AppHeader = ({ authUser, history, firebase }) => {
  const handleSignOut = () => {
    firebase.doSignOut()
      .then(() => {
        history.push(ROUTES.SIGN_IN);
      })
      .catch(error => {
        console.log('error', error)
      });
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ color: '#197D1D', background: 'white',    boxShadow: 'none', borderBottom: '1px solid #C8C8C8' }}>
        <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="h6"  className={classes.pointer} onClick={() => history.push(ROUTES.WIDGETS)}>
          LeagueApps Widgets Setup
          </Typography>
          <Button style={{ color: 'black', textTransform: 'none' }} color="inherit" onClick={() => handleSignOut()}> Logout</Button>
          {/* <Navigation authUser={authUser} history={history} />   */}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default compose(
  withRouter,
  withFirebase
)(AppHeader);