import React from "react";
import { CircularProgress } from "@material-ui/core";

const Loading = ({
  label,
  handler,
  size,
  position,
  left,
  classname = "",
  style,
  ...props
}) => {
  return (
    <div
      style={{
        ...style,
        position: position || "fixed",
        left: left || "50%",
        top: "40%",
        zIndex: 1000,
      }}
    >
      {" "}
      <CircularProgress
        disableShrink
        size={size || 48}
        style={{ color: "#197D1D" }}
      />{" "}
    </div>
  );
};

export default Loading;
