import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "fit-content",
    },
  },
};

export default function MultiSelect({
  selectedValues,
  handleFilterValueSelection,
  id,
  label,
  values,
  index,
}) {
  const classes = useStyles();
  const [selections, setSelections] = React.useState(selectedValues);

  const handleChange = (event) => {
    setSelections(event.target.value);
    handleFilterValueSelection(id, event.target.value, index);
  };

  React.useEffect(() => {
    setSelections(selectedValues);
  }, [index, selectedValues]);

  if (values.length <= 0) {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <Select
            labelId={label}
            id={id}
            label="TETS"
            multiple
            value={selections}
            onChange={handleChange}
            input={<Input />}
            displayEmpty={true}
            renderValue={(selected) =>
              selectedValues.length > 0
                ? `${selectedValues.length} selected`
                : "All"
            }
            MenuProps={MenuProps}
            disableUnderline={true}
            floatPlaceholder="never"
            disabled
            style={{
              backgroundColor: "transparent",
              fontSize: "12px",
              boxShadow: "none",
              color: "#a19898",
              border: "1px solid #a19898",
              textTransform: "none",
              width: "auto",
              minWidth: "120px",
              minHeight: "25px",
              height: "auto",
              justifyContent: "space-around",
              borderRadius: "20px",
              padding: "5px",
              paddingLeft: 15,
              marginRight: "5px",
            }}
          ></Select>
        </FormControl>
      </div>
    );
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          labelId={label}
          id={id}
          label="TETS"
          multiple
          value={selections}
          onChange={handleChange}
          input={<Input />}
          displayEmpty={true}
          renderValue={(selected) =>
            selectedValues.length > 0
              ? `${selectedValues.length} selected`
              : "All"
          }
          MenuProps={MenuProps}
          disableUnderline={true}
          floatPlaceholder="never"
          style={{
            backgroundColor: "transparent",
            fontSize: "12px",
            color: "#228B22",
            boxShadow: "none",
            textTransform: "none",
            width: "auto",
            minWidth: "120px",
            minHeight: "25px",
            height: "auto",
            justifyContent: "space-around",
            borderRadius: "20px",
            border: "1px solid #228B22",
            padding: "5px",
            paddingLeft: 15,
            marginRight: "5px",
          }}
        >
          {values.map((option) => (
            <MenuItem
              style={{
                borderColor: "#228B22",
                borderRadius: "20px",
                minWidth: "200px",
                width: "auto",
              }}
              key={option}
              value={option}
            >
              <Checkbox
                style={{ color: "#197D1D" }}
                checked={selections.indexOf(option) > -1}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
