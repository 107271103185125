import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  isEmpty,
  forEach,
  map,
  find,
  cloneDeep,
  sortBy,
  uniqBy,
  indexOf,
  uniq,
} from "lodash";

import {
  FormLabel,
  Chip,
  Checkbox,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";

import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import faker from "faker";
import axios from "axios";
import jsonpAdapter from "axios-jsonp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CustomizedDialog from "../CommonComponents/ViewCodeDialog";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

import firebase from "firebase";
import { withFirebase } from "../Firebase";

import Loading from "../CommonComponents/Loading";
import AppButton from "../CommonComponents/AppButton";
import MultiSelect from "../CommonComponents/MultiSelect";

import {
  WIDGET_INITIAL_STATE,
  FIREBASAE_COLLECTION,
  filterConfig,
  buttonStyle,
  buttonGreenLabelStyle,
  FILTERS,
  START_WEEK_FROM_MONDAY,
  START_WEEK_FROM_SUNDAY,
  COLUMNS,
  STATES,
} from "../../constants/admin";
import DragableCell from './DragableCell';
import { TabPanel } from './TabPanel';
import { getFilterOptions, generateCodeSnippet, getSiteNameForListing } from '../../Helpers';

import ScheduleWidget from "./scheduleWidget/ScheduleTab";
import { vi } from "faker/lib/locales";
import { BUTTON_TEXTS, formatStateLabels, SIGNUP_ACTIONS } from "../../constants/widget";


const styles = {
  filterButton: {
    backgroundColor: "transparent",
    fontSize: "12px",
    color: "#228B22",
    boxShadow: "none",
    textTransform: "none",
    width: "auto",
    minWidth: "120px",
    minHeight: "25px",
    height: "auto",
    justifyContent: "space-around",
    borderRadius: "20px",
    border: "1px solid #228B22",
    padding: "5px",
    marginRight: "5px",
  },
  filterOptions: {
    borderColor: "#228B22",
    borderRadius: "20px",
    minWidth: "200px",
    width: "auto",
  },
  inputStyles: {
    fontSize: 14,
    padding: 8,
    marginTop: 8,
    boxSizing: "border-box",
    height: 34,
    width: 275,
    border: "1px solid #C8C8C8",
    borderRadius: 3,
  },
};

const tabStyle = {
  default_tab: {
    color: "#197D1D",
    textTransform: "none",
    fontFamily: "Montserrat SemiBold",
    fontSize: 12,
  },
  active_tab: {
    color: "#000000",
    textTransform: "none",
    fontFamily: "Montserrat SemiBold",
    fontSize: 12,
  },
  tabStylePanel: { width: "80%" },
};

const tableCellStyle = {
  slim: { padding: 0 },
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

/**
 * Create a new OR Edit an existing widget
 * Validated fields are
 * 1. Listing Name (Site Info tab, required Field)
 * 2. Organization Name (Site Info tab, required Field)
 * 3. Site Id, Site Name and API key(if a new site info row is added, then required) 
 * 4. Default Zipcode (Settings tab, If show Zipcode Finder is selected)
 * 5. Atleast one column should to selected for display. 
 */
class CreateWidgetBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...cloneDeep(WIDGET_INITIAL_STATE),
    };
  }

  componentWillMount() {
    const {
      match: { params },
      firebase,
    } = this.props;
    if (!params.id) {
      const cols = sortBy(WIDGET_INITIAL_STATE.columns, (c) =>
        c.label.toLowerCase()
      );
      const filters = sortBy(WIDGET_INITIAL_STATE.filters, (c) =>
        c.label.toLowerCase()
      );
      WIDGET_INITIAL_STATE.columns = cols;
      WIDGET_INITIAL_STATE.filters = filters;

      this.setState({
        ...cloneDeep(WIDGET_INITIAL_STATE),
      });
      return;
    }

    this.setState({ isLoading: true }, async () => {
      const widgetRefs = firebase.database.collection(
        FIREBASAE_COLLECTION.widgets
      );
      const result = await widgetRefs.where("uId", "==", params.id).get();
      if (result.empty) {
        this.setState({ isLoading: false });
        return;
      }

      const selectedWidgets = [];
      result.forEach((doc) => {
        selectedWidgets.push(doc.data());
      });
      const selectedWidget = selectedWidgets[0];

      const cols = selectedWidget.columnSettings;
      const filters = selectedWidget.filtersConfig;

      cloneDeep(COLUMNS).forEach((col) => {
        if (!find(selectedWidget.columnSettings, (d) => d.key === col.key)) {
          cols.unshift(col);
        }
      });

      FILTERS.forEach((filter) => {
        if (!find(selectedWidget.filtersConfig, (d) => d.key === filter.key)) {
          filters.unshift(filter);
        }
      });
      this.setState({
        isLoading: false,
        ...selectedWidget,
        codeSnippet: generateCodeSnippet(params.id),
        columns: uniqBy(cols, "key"),
        filters: uniqBy(filters, "key"),
      });
    });
  }

  openSnack = (msg) => {
    this.setState({ showSnack: true });
  };

  closeSnack = () => {
    this.setState({ showSnack: false });
  };

  onCancel = () => this.props.history.push("/widgets");

  /**
   * method to update changes on a existing widget
   * validates all required fields before updating 
   */ 
  updateWidget = () => {
    const { firebase } = this.props;
    const {
      showAdditionalTeamFee,
      showScheduleStandingLinks,
      showHasPaymentPlans,
      showProcessingFee,
      displayPriceLabels,
      siteInfoErrors,
      sitesInfo,
      selectedSiteId,
      uId,
      orgAccountName,
      isSelectAllFilters,
      isAllFiltersVisible,
      siteName,
      listingName,
      notes,
      columns,
      signUpAction,
      signUpButtonText,
      soldOutButtonText,
      filters,
      programStateLabels,
      showZipCodeFinder,
      defaultZipCode,
      defaultSort,
      defaultSortDirection,
      firstDayOfTheWeek,
      scheduleWidget,
      teamWidget,
    } = this.state;

    let allowSave = true;
    const widgetInfo = [];

    forEach(sitesInfo, (siteInfo, index) => {
      if (!siteInfo.siteId) {
        siteInfoErrors.siteId.indexes.push(index);
        allowSave = false;
      }
      if (!siteInfo.apiKey) {
        siteInfoErrors.apiKey.indexes.push(index);
        allowSave = false;
      }
      if (!siteInfo.siteName) {
        siteInfoErrors.siteName.indexes.push(index);
        allowSave = false;
      }
      widgetInfo.push({ siteId: siteInfo.siteId, apiKey: siteInfo.apiKey });
    });
    this.setState({ siteInfoErrors });

    if (!listingName) {
      this.setState({ listingNameError: "Please enter listing name." });
      allowSave = false;
    }
    if (!orgAccountName) {
      this.setState({ orgAccountNameError: "Please enter org Account name." });
      allowSave = false;
    }

    if (showZipCodeFinder === "yes" && isEmpty(defaultZipCode)) {
      this.setState({
        defaultZipCodeError: "Please enter a default zip code.",
      });
      allowSave = false;
    }

    if(columns.every(col => !col.value)) {
      this.setState({ columnsError: "Please select atleast one column to display." });
      allowSave = false;
    }  

    if (!allowSave) {
      return;
    }

    this.setState({ isLoading: true, newAddeSiteIndex: -1 }, async () => {
      const result = await firebase.database
        .collection(FIREBASAE_COLLECTION.widgets)
        .where("uId", "==", uId)
        .get();
      if (result.empty) {
        this.setState({ isLoading: false });
        return;
      }

      const selectedWidgets = [];
      result.forEach((doc) => {
        const data = doc.data();
        selectedWidgets.push({ id: doc.id, ...data });
      });

      const doc = selectedWidgets[0];
      let _selectedSiteId = this.getSelectedSiteId(selectedSiteId, sitesInfo);
      const siteNames = getSiteNameForListing(
        sitesInfo,
        _selectedSiteId,
        siteName
      );
      const _siteName =
        siteNames.selectedSiteName +
        (siteNames.siteName && siteNames.siteName.length > 0
          ? ` + ${siteNames.siteName}`
          : "");
      await firebase.database
        .collection(FIREBASAE_COLLECTION.widgets)
        .doc(doc.id)
        .update({
          listingName,
          orgAccountName: orgAccountName ? orgAccountName : "",
          siteName: _siteName,
          sitesInfo,
          notes,
          signUpButtonText,
          soldOutButtonText,
          signUpAction,
          showProcessingFee,
          displayPriceLabels,
          isSelectAllFilters,
          isAllFiltersVisible,
          showAdditionalTeamFee,
          showScheduleStandingLinks,
          showHasPaymentPlans,
          columnSettings: columns,
          selectedSiteId: _selectedSiteId ? _selectedSiteId : "",
          filtersConfig: filters,
          programStateLabels: formatStateLabels(programStateLabels),
          showZipCodeFinder,
          defaultZipCode,
          defaultSort,
          defaultSortDirection,
          firstDayOfTheWeek,
          scheduleWidget: { ...scheduleWidget },
          teamWidget: { ...teamWidget, teamId: teamWidget.teamId || "" },
        });

      this.setState({ isLoading: false });
    });
  };

  getSelectedSiteId = (selectedSiteId, sitesInfo) => {
    if (selectedSiteId && selectedSiteId.length > 0) {
      return selectedSiteId;
    }

    return sitesInfo && sitesInfo[0] && sitesInfo[0].sitId;
  };

   /**
   * method to create a new widget
   * validates all required fields before updating 
   */ 
  saveWidget = (event) => {
    const { firebase } = this.props;
    const {
      notes,
      showAdditionalTeamFee,
      showScheduleStandingLinks,
      showHasPaymentPlans,
      showProcessingFee,
      displayPriceLabels,
      siteInfoErrors,
      sitesInfo,
      selectedSiteId,
      uplodedFile,
      orgAccountName,
      siteId,
      isSelectAllFilters,
      isAllFiltersVisible,
      siteName,
      apiKey,
      listingName,
      programStateLabels,
      columns,
      signUpAction,
      signUpButtonText,
      soldOutButtonText,
      filters,
      showZipCodeFinder,
      defaultZipCode,
      defaultSort,
      defaultSortDirection,
      firstDayOfTheWeek,
    } = this.state;
    let allowSave = true;
    const widgetInfo = [];

    forEach(sitesInfo, (siteInfo, index) => {
      if (!siteInfo.siteId) {
        siteInfoErrors.siteId.indexes.push(index);
        allowSave = false;
      }
      if (!siteInfo.apiKey) {
        siteInfoErrors.apiKey.indexes.push(index);
        allowSave = false;
      }
      if (!siteInfo.siteName) {
        siteInfoErrors.siteName.indexes.push(index);
        allowSave = false;
      }
      widgetInfo.push({ siteId: siteInfo.siteId, apiKey: siteInfo.apiKey });
    });
    this.setState({ siteInfoErrors });

    if (!listingName) {
      this.setState({ listingNameError: "Please enter listing name." });
      allowSave = false;
    }
    if (!orgAccountName) {
      this.setState({ orgAccountNameError: "Please enter org Account name." });
      allowSave = false;
    }

    if (showZipCodeFinder === "yes" && isEmpty(defaultZipCode)) {
      this.setState({
        defaultZipCodeError: "Please enter a default zip code.",
      });
      allowSave = false;
    }

    if(columns.every(col => !col.value)){
      this.setState({
        columnsError: "Please select atleast on column to display"
      })
      allowSave = false;
    }

    if (!allowSave) {
      return;
    }
    const _selectedSiteId = this.getSelectedSiteId(selectedSiteId, sitesInfo);
    const siteNames = getSiteNameForListing(
      sitesInfo,
      _selectedSiteId,
      siteName
    );
    const _siteName =
      siteNames.selectedSiteName +
      (siteNames.siteName && siteNames.siteName.length > 0
        ? ` + ${siteNames.siteName}`
        : "");

    this.setState({ isLoading: true, newAddeSiteIndex: -1 }, async () => {
      try {
        const randomId = faker.random.uuid();
        await firebase.database.collection(FIREBASAE_COLLECTION.widgets).add({
          apiKey,
          siteId,
          sitesInfo,
          listingName,
          notes,
          showProcessingFee,
          displayPriceLabels,
          uId: randomId,
          signUpButtonText,
          soldOutButtonText,
          signUpAction,
          isSelectAllFilters,
          isAllFiltersVisible,
          showAdditionalTeamFee,
          showHasPaymentPlans,
          showScheduleStandingLinks,
          columnSettings: columns,
          siteName: _siteName,
          filtersConfig: filters,
          programStateLabels: formatStateLabels(programStateLabels),
          isOrignal: true,
          copyIndex: -1,
          uplodedFile: uplodedFile ? uplodedFile : "",
          orgAccountName,
          selectedSiteId: _selectedSiteId ? _selectedSiteId : "",
          codeSnippet: generateCodeSnippet(randomId),
          showZipCodeFinder,
          defaultZipCode,
          defaultSort,
          defaultSortDirection,
          firstDayOfTheWeek,
          scheduleWidget: {
            programId: "",
            siteId: "",
            codeSnippet: "",
          },
          teamWidget: {
            programId: "",
            siteId: "",
            codeSnippet: "",
          },
        });

        this.props.history.push(`edit-widget/${randomId}`);
      } catch (error) {
        this.setState({ error });
      }
      this.setState({ isLoading: false });
    });
    event.preventDefault();
  };

  // fetch filter options list for filter tab dropdowns
  fetchFiltersOptions = () => {
    const { sitesInfo } = this.state;
    this.setState({ isProgramsLoading: true }, async () => {
      const api = axios.create({
        baseURL: `${process.env.REACT_APP_LA_BASE_URL}v1/`,
        adapter: jsonpAdapter,
      });
      try {
        const filterPromises = [];
        forEach(sitesInfo, ({ siteId, apiKey }) => {
          filterPromises.push(
            api.get(
              `${process.env.REACT_APP_LA_BASE_URL}v1/sites/${siteId}/programs/current?la-api-key=${apiKey}`,
              { headers: { "la-api-key": apiKey } }
            )
          );
        });
        const res = await Promise.all(filterPromises);
        let filterValues = {};
        forEach(res, (_res) => {
          const programs = getFilterOptions(
            _res.data,
            sitesInfo,
            this.state.firstDayOfTheWeek
          );
          forEach(programs, (program, key) => {
            const existingFilter = filterValues[key];
            if (existingFilter) {
              filterValues[key].values = uniq([
                ...program.values,
                ...filterValues[key].values,
              ]);
            } else {
              filterValues[key] = program;
            }
          });
        });
        this.setState({
          isProgramsLoading: false,
          filterValues,
          apiError: null,
        });
      } catch (error) {
        console.error("error-A", error);
        this.setState({
          isProgramsLoading: false,
          apiError: "Unable to fetch data against given API Key.",
        });
      }
    });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ activeIndex: newValue });
  };

  setViewCodeOpen = (value) => {
    this.setState({ openDialog: value });
  };

  setCodeContent = (code) => {
    this.setState({ codeSnippet: code });
  };
  getStyle(isActive) {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
  }

  getLabel = (label) => (
    <p
      style={{
        margin: 0,
        color: "#000000",
        fontFamily: "Montserrat Medium",
        fontSize: 12,
        marginBottom: 3,
      }}
    >
      {" "}
      {label}{" "}
    </p>
  );

  onChange = (event) => {
    const { orgAccountName, listingName, defaultZipCode, columns} = this.state;
    let value = event.target.value;
        
    this.setState({ [event.target.name]: value }, () => {
      if (listingName) {
        this.setState({
          listingNameError: undefined,
        });
      }
      if (orgAccountName) {
        this.setState({
          orgAccountNameError: undefined,
        });
      }
      if (defaultZipCode) {
        this.setState({
          defaultZipCodeError: undefined,
        });
      }

      if(columns.every(col => !col.value)){
        this.setState({
          columnsError: undefined
        })
      }
    });
  };

  onCustomInputChange = (event, index) => {
    const { sitesInfo, siteInfoErrors } = this.state;
    const keyName = event.target.name;
    sitesInfo[index][keyName] = event.target.value;
    const siteInfoError = siteInfoErrors[keyName];
    this.setState({ sitesInfo }, () => {
      const foundIndex = indexOf(siteInfoError.indexes, index);
      if (foundIndex >= 0) {
        siteInfoError.indexes[foundIndex] = -1;
        siteInfoErrors[keyName] = siteInfoError;
        this.setState({
          siteInfoErrors,
        });
      }
    });
  };

  editSoldOutLabel = () => {
    const { isEditSoldOut } = this.state;
    this.setState({
      isEditSoldOut: !isEditSoldOut,
    });
  };

  editSignupLabel = () => {
    const { isEditSignUp, signUpButtonText, soldOutButtonText } = this.state;
    this.setState({
      isEditSignUp: !isEditSignUp,
      signUpButtonText: !isEmpty(signUpButtonText) ? signUpButtonText : BUTTON_TEXTS.signUp,
      soldOutButtonText: !isEmpty(soldOutButtonText) ? soldOutButtonText : BUTTON_TEXTS.soldOut
    });
  };

  handleSignUpActionChange = (event) => {
    this.setState({ signUpAction: event.target.value });
  };

  handleSiteChange = (event) => {
    this.setState({ selectedSiteId: event.target.value });
  };

  handleRadioChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAddNewSiteRow = (index) => {
    const { sitesInfo } = this.state;
    sitesInfo.push({
      siteId: undefined,
      apiKey: undefined,
      siteName: undefined,
    });
    this.setState({ sitesInfo, newAddeSiteIndex: index });
  };

  handleRemoveSiteRow = (index) => {
    let { sitesInfo, hoverIndex, newAddeSiteIndex } = this.state;
    const _sitesInfo = cloneDeep(sitesInfo);
    _sitesInfo.splice(index, 1);
    if (sitesInfo.length < 2) {
      hoverIndex = -1;
    }
    this.setState(
      { hoverIndex, newAddeSiteIndex: newAddeSiteIndex - 1 },
      () => {
        this.setState({ sitesInfo: _sitesInfo }, () => {});
      }
    );
  };

  handleColumInputChange = (event) => {
    const { columns, customNameEditIndex } = this.state;
    columns[customNameEditIndex]["customLabel"] = event.target.value;
    this.setState({ columns });
  };

  handleCustomNameEdit = (index) => {
    this.setState({ customNameEditIndex: index });
  };

  handleCustomNameSave = () => {
    this.setState({ customNameEditIndex: -1 });
  };

  handleCustomNameRemove = (index) => {
    const { columns } = this.state;
    columns[index]["customLabel"] = "";
    this.setState({ columns });
  };

  setFilterValue = (value) => {
    const { filterValues } = this.state;
    this.setState({ filterValues, ...value });
  };

  handleFilterOptionChange = (id, selections, index) => {
    const { filters } = this.state;
    filters[index].selectedOptions = selections;
    this.setState({ filters });
  };
  handleVisibleOptionChange = (id, selections, index) => {
    const { filters } = this.state;
    filters[index].visibleOptions = selections;
    this.setState({ filters });
  };

  handleColumnVisibilityChange = (event, index) => {
    const { columns } = this.state;
    columns[index].value = event.target.checked;
    let error = null;

    if(columns.every(col => !col.value)){
      error = "Please select atleast on column to display";
    }

    this.setState({ columns, columnsError: error});
  };

  handleFiltersSelectionChange = (event, index) => {
    const { filters } = this.state;
    filters[index].value = event.target.checked;
    if (event.target.checked) {
      filters[index].visibility = true;
    } else {
      filters[index].visibility = false;
    }

    this.setState({ filters });
  };

  handleEnableRelevancy = (event, index) => {
    const { filters } = this.state;
    filters[index].hasRelevancy = event.target.checked;
    if (event.target.checked) {
      filters[index].hasRelevancy = true;
    } else {
      filters[index].hasRelevancy = false;
    }

    this.setState({ filters });
  };

  moveCell = (dragIndex, hoverIndex) => {
    const { columns } = this.state;
    const dragCard = columns[dragIndex];
    setTimeout(() => {
      this.setState({
        columns: update(columns, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      });
    }, 100);
  };

  moveFilterCell = (dragIndex, hoverIndex) => {
    const { filters } = this.state;
    const dragCard = filters[dragIndex];
    this.setState({
      filters: update(filters, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    });
  };

  handleAllFilterChange = (event) => {
    const { filters } = this.state;
    const _filters = filters.map((filter, index) => {
      filters[index].value = event.target.checked;
      if (event.target.checked) {
        filters[index].visibility = true;
      } else {
        filters[index].visibility = false;
      }
      return filter;
    });

    this.setState({
      filters: _filters,
      isSelectAllFilters: event.target.checked,
    });
  };

  handleAllFilterVisibilityChange = (status) => {
    const { filters } = this.state;
    const _filters = filters.map((filter, index) => {
      filters[index].visibility = status;
      return filter;
    });

    this.setState({ filters: _filters, isAllFiltersVisible: status });
  };

  handleFilterVisibilityChange = (status, index) => {
    const { filters } = this.state;
    filters[index].visibility = status;
    this.setState({ filters });
  };

  handleDeleteFilterSelection = (value, index) => {
    const { filters } = this.state;
    const filter = filters[index];
    const options = filter.selectedOptions.filter(
      (selectedValue, index, arr) => {
        return value !== selectedValue;
      }
    );
    filters[index].selectedOptions = options;
    this.setState({ filters });
  };

  handleDeleteFilterNameSelection = (value, index) => {
    const { filters } = this.state;
    filters[index].text = "";
    this.setState({ filters });
  };

  handleFilterNameChange = (event, index) => {
    const { filters } = this.state;
    filters[index].text = event.target.value;
    this.setState({ filters });
  };

  onChangeProgramStatusLabel = (value, key) => {
    let statusLabels = this.state.programStateLabels;

    if (isEmpty(value)) {
      statusLabels[key] = value;
    } else {
      statusLabels[key] = value;
    }

    this.setState({
      programStateLabels: statusLabels,
    });
  };

  getCustomColumn = (customNameEditIndex, index) => {
    const { columns } = this.state;
    const column = columns[index];

    if (customNameEditIndex !== index && column.customLabel.length) {
      return (
        <span>
          <span
            style={{
              fontWeight: 400,
              letterSpacing: "0.00938em",
              color: "#black",
              fontFamily: "Montserrat",
              fontSize: 12,
            }}
          >
            {column.customLabel}
          </span>

          <span
            onClick={() => this.handleCustomNameEdit(index)}
            style={{
              marginLeft: 7,
              marginTop: 10,
              fontFamily: "Montserrat",
              fontSize: 12,
              color: "#197D1D",
              cursor: "pointer",
            }}
          >
            Edit
          </span>
          <span
            onClick={() => this.handleCustomNameRemove(index)}
            style={{
              marginLeft: 7,
              marginTop: 10,
              fontFamily: "Montserrat",
              fontSize: 12,
              color: "#197D1D",
              cursor: "pointer",
            }}
          >
            Remove
          </span>
        </span>
      );
    }

    if (customNameEditIndex !== index) {
      return (
        <span
          style={{
            cursor: "pointer",
            color: "#197D1D",
            fontFamily: "Montserrat",
            fontSize: 12,
          }}
          onClick={() => this.handleCustomNameEdit(index)}
        >
          Add custom name
        </span>
      );
    }

    if (customNameEditIndex === index) {
      return (
        <div style={{ display: "inline-flex" }}>
          <FormControl
            margin="normal"
            required
            style={{ marginTop: 0, width: 120 }}
          >
            <input
              name={"customLabel"}
              type="text"
              value={column.customLabel}
              onChange={(event) => this.handleColumInputChange(event, index)}
              style={{
                fontSize: 14,
                padding: 8,
                marginTop: 8,
                boxSizing: "border-box",
                height: 34,
                width: 120,
                border: "1px solid #C8C8C8",
                borderRadius: 3,
              }}
            />
          </FormControl>
          <span
            onClick={this.handleCustomNameSave}
            style={{
              marginLeft: 7,
              marginTop: 10,
              lineHeight: 2.3,
              cursor: "pointer",
              color: "#197D1D",
              fontFamily: "Montserrat",
              fontSize: 12,
            }}
          >
            Save
          </span>
        </div>
      );
    }
  };

  showDeleteButton = (sitesInfo, index) => {
    return sitesInfo.length > 1 && this.setState({ hoverIndex: index });
  };

  hideDeleteButton = () => {
    return this.setState({ hoverIndex: -1 });
  };

  handleNoteChange = (event) => {
    this.setState({
      notes: event.target.value,
    });
  };

  handleSetDefaultSort = ({ target: { value } }) => {
    this.setState({
      defaultSort: value,
    });
  };

  handleSortDirectionChange = () => {
    const { defaultSortDirection } = this.state;
    const sortDirection = defaultSortDirection === "asc" ? "desc" : "asc";
    this.setState({ defaultSortDirection: sortDirection });
  };

  onFileUpload = (event, index) => {
    const { sitesInfo } = this.state;
    const file = event.target.files[0];
    const fileName = `${sitesInfo[index].apiKey}.p12`;
    const storageRef = firebase.storage().ref(fileName);

    try {
      storageRef.put(file).then((snapshot) => {
        console.log("Uploaded.");
      });
    } catch (err) {
      console.log("Error in uploading", err);
    }

    const keyName = event.target.name;
    sitesInfo[index][keyName] = fileName;
    this.setState({ sitesInfo });
  };

  getSiteInfoTab = () => {
    let tabIndex = 1;
    const {
      newAddeSiteIndex,
      siteInfoErrors,
      sitesInfo,
      orgAccountNameError,
      uId,
      orgAccountName,
      listingName,
      listingNameError,
    } = this.state;
    const sitesLength = sitesInfo.length;
    return (
      <>
        <Typography
          component="h5"
          variant="h5"
          style={{
            color: "#000000",
            fontFamily: "Montserrat Medium",
            fontSize: 14,
          }}
        >
          Site information
        </Typography>

        {map(sitesInfo, (siteInfo, index) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControl margin="normal" required style={{ width: 300 }}>
                  {this.getLabel("Site ID")}

                  {uId && newAddeSiteIndex !== index ? (
                    <input
                      type="text"
                      style={styles.inputStyles}
                      name="siteId"
                      key={`siteId${siteInfo.siteId}`}
                      value={siteInfo.siteId}
                      tabIndex={tabIndex++}
                      onChange={(e) => this.onCustomInputChange(e, index)}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      style={styles.inputStyles}
                      name="siteId"
                      tabIndex={tabIndex++}
                      key={`siteId${index}-a`}
                      value={siteInfo.siteId}
                      onChange={(e) => this.onCustomInputChange(e, index)}
                    />
                  )}

                  {indexOf(siteInfoErrors.siteId.indexes, index) >= 0 && (
                    <p style={{ color: "red" }}>
                      {siteInfoErrors.siteId.message}
                    </p>
                  )}
                </FormControl>

                {index === sitesLength - 1 && (
                  <FormControl margin="normal" required style={{ width: 300 }}>
                    {this.getLabel("Org Account name")}
                    <input
                      type="text"
                      tabIndex="40"
                      style={styles.inputStyles}
                      name="orgAccountName"
                      value={orgAccountName}
                      onChange={this.onChange}
                    />
                    {orgAccountNameError && (
                      <p style={{ color: "red" }}>{orgAccountNameError}</p>
                    )}
                  </FormControl>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControl
                  margin="normal"
                  required
                  style={{ marginLeft: "2vw", width: 300 }}
                >
                  {this.getLabel("API key")}
                  {uId && newAddeSiteIndex !== index ? (
                    <input
                      type="text"
                      style={styles.inputStyles}
                      name="apiKey"
                      tabIndex={tabIndex++}
                      key={`apiKey${siteInfo.apiKey}`}
                      value={siteInfo.apiKey}
                      onChange={(e) => this.onCustomInputChange(e, index)}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      style={styles.inputStyles}
                      name="apiKey"
                      tabIndex={tabIndex++}
                      key={`apiKey${index}-a`}
                      value={siteInfo.apiKey}
                      onChange={(e) => this.onCustomInputChange(e, index)}
                    />
                  )}

                  {indexOf(siteInfoErrors.apiKey.indexes, index) >= 0 && (
                    <p style={{ color: "red" }}>
                      {siteInfoErrors.apiKey.message}
                    </p>
                  )}
                </FormControl>
                {index === sitesLength - 1 && (
                  <FormControl
                    margin="normal"
                    required
                    style={{ marginLeft: "2vw", width: 300 }}
                  >
                    {this.getLabel("Listing name")}
                    <input
                      type="text"
                      style={styles.inputStyles}
                      name="listingName"
                      tabIndex="51"
                      value={listingName}
                      onChange={this.onChange}
                    />
                    {listingNameError && (
                      <p style={{ color: "red" }}>{listingNameError}</p>
                    )}
                  </FormControl>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FormControl
                    margin="normal"
                    required
                    style={{ marginLeft: "2vw", width: 300 }}
                  >
                    {this.getLabel("Site Name")}
                    {uId && newAddeSiteIndex !== index ? (
                      <input
                        type="text"
                        style={styles.inputStyles}
                        name="siteName"
                        tabIndex={tabIndex++}
                        key={`siteName${siteInfo.siteName}`}
                        value={siteInfo.siteName}
                        onChange={(e) => this.onCustomInputChange(e, index)}
                        disabled
                      />
                    ) : (
                      <input
                        type="text"
                        style={styles.inputStyles}
                        name="siteName"
                        tabIndex={tabIndex++}
                        key={`siteName${index}-a`}
                        value={siteInfo.siteName}
                        onChange={(e) => this.onCustomInputChange(e, index)}
                      />
                    )}

                    {indexOf(siteInfoErrors.siteName.indexes, index) >= 0 && (
                      <p style={{ color: "red" }}>
                        {siteInfoErrors.siteName.message}
                      </p>
                    )}
                  </FormControl>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FormControl
                    margin="normal"
                    required
                    style={{ marginLeft: "2vw", width: 300 }}
                  >
                    {this.getLabel("P12 Key File Upload")}
                    <input
                      type="file"
                      name="keyFile"
                      accept="application/x-pkcs12"
                      onChange={(e) => this.onFileUpload(e, index)}
                    />
                    {siteInfo.keyFile && this.getLabel(siteInfo.keyFile)}
                  </FormControl>

                  {uId &&
                  this.state.hoverIndex === index &&
                  sitesInfo.length > 1 ? (
                    <RemoveCircle
                      onMouseEnter={() =>
                        this.showDeleteButton(sitesInfo, index)
                      }
                      onMouseLeave={this.hideDeleteButton}
                      fontSize="large"
                      onClick={() => this.handleRemoveSiteRow(index)}
                      style={{ color: "red", cursor: "pointer", marginTop: 44 }}
                    />
                  ) : (
                    uId &&
                    index + 1 !== sitesInfo.length &&
                    sitesInfo.length > 1 && (
                      <div
                        style={{
                          width: "5vw",
                          height: "4vh",
                          marginTop: "auto",
                        }}
                        onMouseEnter={() =>
                          this.showDeleteButton(sitesInfo, index)
                        }
                        onMouseLeave={this.hideDeleteButton}
                      ></div>
                    )
                  )}

                  {uId &&
                    index + 1 === sitesInfo.length &&
                    sitesInfo.length > 1 && (
                      <div style={{ width: "4vw" }}>
                        <RemoveCircle
                          fontSize="large"
                          onClick={() => this.handleRemoveSiteRow(index)}
                          style={{
                            color: "red",
                            cursor: "pointer",
                            marginTop: 44,
                          }}
                        />
                        <AddCircle
                          fontSize="large"
                          onClick={() => this.handleAddNewSiteRow(index + 1)}
                          style={{
                            color: "#197D1D",
                            cursor: "pointer",
                            marginTop: 44,
                          }}
                        />
                      </div>
                    )}

                  {uId &&
                    index + 1 === sitesInfo.length &&
                    sitesInfo.length <= 1 && (
                      <div style={{ width: "4vw" }}>
                        <AddCircle
                          fontSize="large"
                          onClick={() => this.handleAddNewSiteRow(index + 1)}
                          style={{
                            color: "#197D1D",
                            cursor: "pointer",
                            marginTop: 44,
                          }}
                        />
                      </div>
                    )}

                  {!uId &&
                    (index + 1 === sitesInfo.length ? (
                      <AddCircle
                        fontSize="large"
                        onClick={() => this.handleAddNewSiteRow(index + 1)}
                        style={{
                          color: "#197D1D",
                          cursor: "pointer",
                          marginTop: 44,
                        }}
                      />
                    ) : (
                      !uId && (
                        <RemoveCircle
                          fontSize="large"
                          onClick={() => this.handleRemoveSiteRow(index)}
                          style={{
                            color: "red",
                            cursor: "pointer",
                            marginTop: 44,
                          }}
                        />
                      )
                    ))}
                </div>
              </div>
            </div>
          );
        })}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControl margin="normal" required style={{ width: 600 }}>
            {this.getLabel("Notes")}
            <textarea
              id="notes"
              name="notes"
              key="notes"
              rows="7"
              tabIndex="125"
              cols="50"
              style={{ border: "2px solid gray" }}
              value={this.state.notes}
              onChange={this.handleNoteChange}
            ></textarea>
          </FormControl>
        </div>
      </>
    );
  };

  getDefaultSiteId = (selectedSiteId) => {
    const { sitesInfo } = this.state;
    if (!selectedSiteId && sitesInfo.length > 0 && sitesInfo[0].siteId) {
      this.setState({
        selectedSiteId: sitesInfo[0].siteId,
      });
      return sitesInfo[0].siteId;
    }
    return selectedSiteId || "";
  };

  getDefaultSiteMessage = (sites) => {
    if (sites.length > 1) {
      return (
        <MenuItem style={styles.filterOptions} value="">
          Select Site
        </MenuItem>
      );
    }
    if (!(sites.length && sites[0].siteId)) {
      return (
        <MenuItem style={styles.filterOptions} value="">
          No site available
        </MenuItem>
      );
    } else {
      return (
        <MenuItem style={styles.filterOptions} value="">
          Select Site
        </MenuItem>
      );
    }
  };

  // change tab method
  handleTabChange = (event, newValue) => {
    this.setState({ activeIndex: newValue });
  };

  // Methods to render tab views
  getSettingsTab = () => {
    const {
      displayPriceLabels,
      showAdditionalTeamFee,
      showScheduleStandingLinks,
      showHasPaymentPlans,
      showProcessingFee,
      isEditSignUp,
      isEditSoldOut,
      soldOutButtonText,
      signUpButtonText,
      signUpAction,
      sitesInfo,
      selectedSiteId,
      programStateLabels,
      showZipCodeFinder,
      defaultZipCode,
      defaultZipCodeError,
      firstDayOfTheWeek,
    } = this.state;
    const sites = map(sitesInfo, (site) => ({
      siteId: site.siteId,
      siteName: site.siteName,
    }));
    return (
      <>
        <Typography
          component="h5"
          variant="h5"
          style={{
            color: "#000000",
            fontFamily: "Montserrat Medium",
            fontSize: 14,
          }}
        >
          Settings
        </Typography>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "33%" }}>
            {/* zip code finder related settings*/}
            <FormControl
              component="fieldset"
              style={{ marginTop: 20, marginBottom: 25 }}
              name=""
            >
              <FormLabel
                component="legend"
                style={{
                  color: "#000000",
                  fontFamily: "Montserrat",
                  fontSize: 12,
                }}
              >
                Show zip code finder
              </FormLabel>
              <RadioGroup
                defaultValue="no"
                aria-label=""
                name="showZipCodeFinder"
                style={{ display: "flex", flexDirection: "row" }}
                value={showZipCodeFinder}
                onChange={this.handleRadioChange}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      style={{
                        color: "#197D1D",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      style={{
                        color: "#197D1D",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>

            {showZipCodeFinder && showZipCodeFinder === "yes" && (
              <FormControl
                margin="normal"
                required
                style={{ marginTop: 19, marginLeft: 40, width: 120 }}
              >
                <FormLabel
                  component="legend"
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: 12,
                  }}
                >
                  Default Zip Code
                </FormLabel>
                <input
                  type="text"
                  name="defaultZipCode"
                  value={defaultZipCode}
                  onChange={this.onChange}
                  style={{
                    fontSize: 14,
                    padding: 8,
                    marginTop: 8,
                    boxSizing: "border-box",
                    height: 34,
                    width: "100%",
                    border: "1px solid #C8C8C8",
                    borderRadius: 3,
                  }}
                />
                {!isEmpty(defaultZipCodeError) && (
                  <p style={{ color: "red" }}>{defaultZipCodeError}</p>
                )}
              </FormControl>
            )}

            <Typography
              component="h6"
              variant="h6"
              style={{
                marginTop: 10,
                marginBottom: 10,
                color: "#000000",
                fontFamily: "Montserrat Medium",
                fontSize: 12,
              }}
            >
              Button text
            </Typography>

            <div style={{ marginBottom: 20, display: "flex" }}>
              {isEditSignUp ? (
                <FormControl
                  margin="normal"
                  required
                  style={{ marginTop: 0, width: 120 }}
                >
                  <input
                    type="text"
                    name="signUpButtonText"
                    value={signUpButtonText}
                    placeholder={BUTTON_TEXTS.signUp}
                    onChange={this.onChange}
                    style={{
                      fontSize: 14,
                      padding: 8,
                      marginTop: 8,
                      boxSizing: "border-box",
                      height: 34,
                      width: "100%",
                      border: "1px solid #C8C8C8",
                      borderRadius: 3,
                    }}
                  />
                </FormControl>
              ) : (
                <AppButton
                  label={signUpButtonText}
                  classname="sign-in-btn"
                  labelStyle={buttonStyle}
                  style={{
                    width: 140,
                    lineHeight: 2.3,
                    height: 27,
                    borderRadius: 25,
                  }}
                />
              )}
              <span
                onClick={this.editSignupLabel}
                style={{
                  marginLeft: 7,
                  marginTop: 10,
                  lineHeight: 2.3,
                  cursor: "pointer",
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  color: "#197D1D",
                }}
              >
                {isEditSignUp ? "Save" : "Edit"}
              </span>
            </div>

            <div style={{ display: "flex" }}>
              {isEditSoldOut ? (
                <FormControl
                  margin="normal"
                  required
                  style={{ marginTop: 0, width: 120 }}
                >
                  <input
                    type="text"
                    name="soldOutButtonText"
                    value={soldOutButtonText}
                    placeholder={BUTTON_TEXTS.soldOut}
                    onChange={this.onChange}
                    style={{
                      fontSize: 14,
                      padding: 8,
                      marginTop: 8,
                      boxSizing: "border-box",
                      height: 34,
                      width: "100%",
                      border: "1px solid #C8C8C8",
                      borderRadius: 3,
                    }}
                    labelStyle={buttonGreenLabelStyle}
                  />
                </FormControl>
              ) : (
                <AppButton
                  label={soldOutButtonText}
                  style={{
                    width: 140,
                    height: 27,
                    borderRadius: 25,
                    marginRight: 5,
                  }}
                />
              )}
              <span
                onClick={this.editSoldOutLabel}
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  color: "#197D1D",
                  marginLeft: 5,
                  marginTop: 10,
                  lineHeight: 2.3,
                  cursor: "pointer",
                }}
              >
                {isEditSoldOut ? "Save" : "Edit"}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl
                style={{
                  width: "30%",
                  marginTop: "4vh",
                }}
              >
                <label
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: 12,
                  }}
                >
                  Button Destination
                </label>
                <Select
                  displayEmpty
                  id="sign-up-action"
                  value={signUpAction}
                  style={styles.filterButton}
                  onChange={this.handleSignUpActionChange}
                  disableUnderline={true}
                  label={
                    SIGNUP_ACTIONS[
                      !isEmpty(signUpAction) ? signUpAction : "none"
                    ]
                  }
                >
                  {Object.keys(SIGNUP_ACTIONS).map((key) => (
                    <MenuItem
                      style={styles.filterOptions}
                      value={SIGNUP_ACTIONS[key].value}
                    >
                      <em>{SIGNUP_ACTIONS[key].label}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                style={{
                  width: "30%",
                  marginTop: "4vh",
                }}
              >
                <label
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: 12,
                  }}
                >
                  Site Theme
                </label>
                <Select
                  id="site-selection"
                  displayEmpty
                  value={this.getDefaultSiteId(selectedSiteId)}
                  style={styles.filterButton}
                  onChange={this.handleSiteChange}
                  disableUnderline={true}
                  floatPlaceholder="never"
                >
                  {this.getDefaultSiteMessage(sites)}
                  {map(sites, (_site) => (
                    <MenuItem style={styles.filterOptions} value={_site.siteId}>
                      {_site.siteName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={{ width: "33%" }}>
            <Typography
              component="h6"
              variant="h6"
              style={{
                marginTop: 10,
                marginBottom: 10,
                color: "#000000",
                fontFamily: "Montserrat Medium",
                fontSize: 12,
              }}
            >
              Program State Option Labels
            </Typography>

            {map(programStateLabels, (value, key) => {
              return (
                <FormControl
                component="fieldset"
                  margin="normal"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 0,
                    paddingRight: 25
                  }}
                >
                  <FormLabel
                    component="legend"
                    style={{
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: 12,
                    }}
                  >
                    {STATES[key]}
                  </FormLabel>
                  <br/>
                  <input
                    type="text"
                    id={key}
                    name=""
                    placeholder={STATES[key]}
                    value={value}
                    onChange={({ target: { value: _value } }) =>
                      this.onChangeProgramStatusLabel(_value, key)
                    }
                    style={{
                      fontSize: 14,
                      padding: 8,
                      marginTop: 8,
                      boxSizing: "border-box",
                      height: 34,
                      width: "100%",
                      border: "1px solid #C8C8C8",
                      borderRadius: 3,
                    }}
                    labelStyle={buttonGreenLabelStyle}
                  />
                </FormControl>
              );
            })}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormControl
                component="fieldset"
                style={{ marginTop: 20, marginBottom: 25 }}
                name=""
              >
                <FormLabel
                  component="legend"
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: 12,
                  }}
                >
                  Display Processing Fees
                </FormLabel>
                <RadioGroup
                  defaultValue="no"
                  aria-label=""
                  name="showProcessingFee"
                  style={{ display: "flex", flexDirection: "row" }}
                  value={showProcessingFee}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl
                component="fieldset"
                style={{ marginTop: 20, marginBottom: 25, marginLeft: 30 }}
                name=""
              >
                <FormLabel
                  component="legend"
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: 12,
                  }}
                >
                  Display Early/Late Price Labels
                </FormLabel>
                <RadioGroup
                  defaultValue="no"
                  aria-label=""
                  name="displayPriceLabels"
                  style={{ display: "flex", flexDirection: "row" }}
                  value={displayPriceLabels}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormControl
                component="fieldset"
                style={{ marginTop: 20, marginBottom: 25 }}
                name=""
              >
                <FormLabel
                  component="legend"
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: 12,
                  }}
                >
                  Show Payment Plans Label
                </FormLabel>
                <RadioGroup
                  defaultValue="no"
                  aria-label=""
                  name="showHasPaymentPlans"
                  style={{ display: "flex", flexDirection: "row" }}
                  value={showHasPaymentPlans}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl
                component="fieldset"
                style={{ marginTop: 20, marginBottom: 25, marginLeft: 30 }}
                name=""
              >
                <FormLabel
                  component="legend"
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: 12,
                  }}
                >
                  Show Additional Team Fees
                </FormLabel>
                <RadioGroup
                  defaultValue="no"
                  aria-label=""
                  name="showAdditionalTeamFee"
                  style={{ display: "flex", flexDirection: "row" }}
                  value={showAdditionalTeamFee}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormControl
                component="fieldset"
                style={{ marginTop: 20, marginBottom: 25 }}
                name=""
              >
                <FormLabel
                  component="legend"
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: 12,
                  }}
                >
                  Show Schedule/Standing links for live programs
                </FormLabel>
                <RadioGroup
                  defaultValue="yes"
                  aria-label=""
                  name="showScheduleStandingLinks"
                  style={{ display: "flex", flexDirection: "row" }}
                  value={showScheduleStandingLinks}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        style={{
                          color: "#197D1D",
                          fontFamily: "Montserrat",
                          fontSize: 12,
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div style={{ width: "33%" }}>
            <FormControl
              component="fieldset"
              style={{ marginTop: 20, marginBottom: 25 }}
              name=""
            >
              <FormLabel
                component="legend"
                style={{
                  color: "#000000",
                  fontFamily: "Montserrat",
                  fontSize: 12,
                }}
              >
                First Day of the Week
              </FormLabel>
              <RadioGroup
                defaultValue={firstDayOfTheWeek}
                aria-label=""
                name="firstDayOfTheWeek"
                style={{ display: "flex", flexDirection: "row" }}
                value={firstDayOfTheWeek}
                onChange={this.handleRadioChange}
              >
                <FormControlLabel
                  value="Monday"
                  control={
                    <Radio
                      style={{
                        color: "#197D1D",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                      }}
                    />
                  }
                  label="Monday"
                />
                <FormControlLabel
                  value="Sunday"
                  control={
                    <Radio
                      style={{
                        color: "#197D1D",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                      }}
                    />
                  }
                  label="Sunday"
                />
              </RadioGroup>
              <FormLabel
                component="legend"
                style={{
                  color: "#000000",
                  fontFamily: "Montserrat",
                  fontSize: 12,
                }}
              >
                Week Days:{" "}
                {firstDayOfTheWeek === "Monday"
                  ? START_WEEK_FROM_MONDAY.join(", ")
                  : START_WEEK_FROM_SUNDAY.join(", ")}
              </FormLabel>
            </FormControl>
          </div>
        </div>
      </>
    );
  };

  getColumnsTab = () => {
    const { customNameEditIndex, columns, columnsError } = this.state;
    return (
      <>
        <Typography
          component="h6"
          variant="h5"
          style={{
            color: "#000000",
            fontFamily: "Montserrat Medium",
            fontSize: 14,
          }}
        >
          Columns
        </Typography>
        <span
          style={{ color: "#000000", fontFamily: "Montserrat", fontSize: 12 }}
        >
          What columns would you like to display?
        </span>

        {!isEmpty(columnsError) && (
          <p style={{ color: "red" }}>{columnsError}</p>
        )}

        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={tableCellStyle.slim}></TableCell>
              <TableCell
                style={{
                  ...tableCellStyle.slim,
                  color: "#A2A2A2",
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                }}
              >
                Sort By
              </TableCell>
              <TableCell
                style={{
                  ...tableCellStyle.slim,
                  color: "#A2A2A2",
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                }}
              />
              <TableCell
                style={{
                  padding: 0,
                  color: "#197D1D",
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                }}
              >
                Column name
              </TableCell>
              <TableCell
                style={{
                  ...tableCellStyle.slim,
                  color: "#A2A2A2",
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                }}
                align="left"
              >
                Custom Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columns.map((row, index) => {
              return (
                <TableRow key={row.name}>
                  <DragableCell
                    key={`column-${row.id}`}
                    index={index}
                    id={row.id}
                    text={row.label}
                    moveCell={this.moveCell}
                  />
                  <TableCell style={{ ...tableCellStyle.slim }}>
                    <Radio
                      checked={this.state.defaultSort === row.id}
                      key={`sort-${row.id}`}
                      style={{ color: "#197D1D" }}
                      onChange={this.handleSetDefaultSort}
                      value={row.id}
                    />
                  </TableCell>

                  <TableCell style={{ ...tableCellStyle.slim }}>
                    {this.state.defaultSort === row.id && (
                      <Button onClick={this.handleSortDirectionChange}>
                        {this.state.defaultSortDirection}
                      </Button>
                    )}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={tableCellStyle.slim}
                  >
                    <Checkbox
                      checked={row.value}
                      style={{
                        color: "#197D1D",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                      }}
                      onChange={(event) =>
                        this.handleColumnVisibilityChange(event, index)
                      }
                      value={row.value}
                    />
                    {/* } */}
                    <label
                      style={{
                        color: "#000000",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                      }}
                    >
                      {row.label}
                    </label>
                    {/* /> */}
                  </TableCell>
                  <TableCell align="left" style={tableCellStyle.slim}>
                    {this.getCustomColumn(customNameEditIndex, index, row)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };
  getVisibleOptions = (options, index, filter) => {
    const { filterValues } = this.state;

    if (filter?.id === "name") {
      return (
        <input
          type="text"
          name="soldOutButtonText"
          value={filter?.text}
          onChange={(event) => this.handleFilterNameChange(event, index)}
          style={{
            fontSize: 14,
            padding: 8,
            marginTop: 8,
            // boxSizing: 'border-box', borderRadius: 5
            boxSizing: "border-box",
            height: 34,
            width: 175,
            border: "1px solid #C8C8C8",
            borderRadius: 3,
          }}
        />
      );
    }

    if (!filterValues) {
      return;
    }
    const selectedFilter = find(filterConfig, (d) => d.id === filter?.id);

    if (selectedFilter && !isEmpty(filterValues[filter.id]?.values)) {
      return (
        <MultiSelect
          handleFilterValueSelection={this.handleVisibleOptionChange}
          id={filter?.id}
          index={index}
          label={filter.label}
          values={filterValues[filter?.id].values}
          selectedValues={options ? options : filterValues[filter?.id].values}
        />
      );
    } else {
      return (
        <MultiSelect
          handleFilterValueSelection={this.handleVisibleOptionChange}
          id={filter?.id}
          index={index}
          label={filter?.label}
          values={[]}
          selectedValues={[]}
          disabled={true}
        />
      );
    }
  };

  getFilterWithValues = (options, index, filter) => {
    const { filterValues } = this.state;
    if (filter.id === "name") {
      return (
        <input
          type="text"
          name="soldOutButtonText"
          value={filter.text}
          onChange={(event) => this.handleFilterNameChange(event, index)}
          style={{
            fontSize: 14,
            padding: 8,
            marginTop: 8,
            // boxSizing: 'border-box', borderRadius: 5
            boxSizing: "border-box",
            height: 34,
            width: 175,
            border: "1px solid #C8C8C8",
            borderRadius: 3,
          }}
        />
      );
    }

    if (!filterValues) {
      return;
    }
    const selectedFilter = find(filterConfig, (d) => d.id === filter.id);

    if (selectedFilter && !isEmpty(filterValues[filter.id].values)) {
      return (
        <MultiSelect
          handleFilterValueSelection={this.handleFilterOptionChange}
          id={filter.id}
          index={index}
          label={filter.label}
          values={filterValues[filter.id].values}
          selectedValues={options}
        />
      );
    } else {
      return (
        <MultiSelect
          handleFilterValueSelection={this.handleFilterOptionChange}
          id={filter.id}
          index={index}
          label={filter.label}
          values={[]}
          selectedValues={[]}
          disabled={true}
        />
      );
    }
  };

  getFiltersTab = () => {
    const {
      filters,
      isSelectAllFilters,
      isAllFiltersVisible,
      isProgramsLoading,
      apiError,
    } = this.state;
    if (isProgramsLoading) {
      return <Loading />;
    }
    return (
      <>
        <Typography
          component="h5"
          variant="h5"
          style={{
            color: "#000000",
            fontFamily: "Montserrat Medium",
            fontSize: 14,
          }}
        >
          Filters
        </Typography>
        {apiError && <p style={{ color: "red" }}>{apiError}</p>}
        <p style={{ color: "#000000", fontFamily: "Montserrat", fontSize: 12 }}>
          What filters would you like to display? To change the default value,
          select their values from drop down
        </p>
        <span
          style={{ color: "#000000", fontFamily: "Montserrat", fontSize: 10 }}
        >
          Note: If 'Show Filtered Options Only' is checked, all options, not
          present in the program list, will be removed.
        </span>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ ...tableCellStyle.slim, width: 20 }}
              ></TableCell>
              <TableCell style={{ ...tableCellStyle.slim, width: 20 }}>
                <Checkbox
                  checked={isSelectAllFilters}
                  key="all-filters"
                  style={{ color: "#197D1D" }}
                  onChange={this.handleAllFilterChange}
                  value={isSelectAllFilters}
                />
              </TableCell>
              <TableCell style={{ ...tableCellStyle.slim, width: 20 }}>
                {isAllFiltersVisible ? (
                  <VisibilityIcon
                    onClick={() => this.handleAllFilterVisibilityChange(false)}
                    style={{
                      color: "#197D1D",
                      marginTop: 5,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => this.handleAllFilterVisibilityChange(true)}
                    style={{ color: "gray", marginTop: 5, cursor: "pointer" }}
                  />
                )}
              </TableCell>
              <TableCell
                colSpan={2}
                style={{
                  ...tableCellStyle.slim,
                  width: 50,
                  color: "#000000",
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                Filter
              </TableCell>
              <TableCell
                colSpan={2}
                style={{
                  ...tableCellStyle.slim,
                  width: 50,
                  color: "#000000",
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                Relevancy
              </TableCell>
              <TableCell
                style={{
                  color: "#A2A2A2",
                  padding: 0,
                  width: 50,
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                  textAlign: "center",
                }}
                align="left"
              >
                Default Options
              </TableCell>
              <TableCell
                style={{
                  color: "#A2A2A2",
                  padding: 0,
                  width: 200,
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                  textAlign: "center",
                }}
                align="left"
              >
                Selections
              </TableCell>
              <TableCell
                colSpan={2}
                style={{
                  color: "#A2A2A2",
                  padding: 0,
                  width: 50,
                  fontFamily: "Montserrat SemiBold",
                  fontSize: 12,
                  textAlign: "center",
                }}
                align="left"
              >
                Visible Options
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filters.map((filter, index) => {
              return (
                <TableRow key={filter.key}>
                  <DragableCell
                    key={filter.id}
                    index={index}
                    id={filter.id}
                    text={filter.label}
                    moveCell={this.moveFilterCell}
                  />
                  <TableCell
                    align="left"
                    style={{ ...tableCellStyle.slim, width: 20 }}
                  >
                    <Checkbox
                      id={filter.id}
                      key={filter.id}
                      checked={filter.value}
                      style={{ color: "#197D1D" }}
                      onChange={(event) =>
                        this.handleFiltersSelectionChange(event, index)
                      }
                      value={filter.value}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      ...tableCellStyle.slim,
                      width: 20,
                      textAlign: "center",
                    }}
                  >
                    {filter.visibility ? (
                      <VisibilityIcon
                        onClick={() =>
                          this.handleFilterVisibilityChange(false, index)
                        }
                        style={{
                          color: "#197D1D",
                          marginTop: 5,
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() =>
                          this.handleFilterVisibilityChange(true, index)
                        }
                        style={{
                          color: "gray",
                          marginTop: 5,
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    component="th"
                    colSpan={2}
                    scope="row"
                    style={{
                      ...tableCellStyle.slim,
                      width: 50,
                      textAlign: "center",
                    }}
                  >
                    {filter.label}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      ...tableCellStyle.slim,
                      width: 20,
                      textAlign: "center",
                    }}
                  >
                    <Checkbox
                      id={`relevancy-${filter.id}`}
                      checked={filter.hasRelevancy !== false}
                      key="has-relevancy"
                      style={{ color: "#197D1D" }}
                      onChange={(event) =>
                        this.handleEnableRelevancy(event, index)
                      }
                      value={filter.hasRelevancy}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ ...tableCellStyle.slim, width: 50 }}
                  >
                    {this.getFilterWithValues(
                      filter.selectedOptions,
                      index,
                      filter
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ ...tableCellStyle.slim, width: 200 }}
                  >
                    <span
                      style={{ padding: 0, display: "flex", flexWrap: "wrap" }}
                    >
                      {filter.text && (
                        <Chip
                          label={filter.text}
                          onDelete={() =>
                            this.handleDeleteFilterNameSelection(
                              filter.text,
                              index
                            )
                          }
                          deleteIcon={
                            <CancelOutlinedIcon style={{ color: "white" }} />
                          }
                          style={{
                            backgroundColor: "#197D1D",
                            color: "white",
                            margin: 5,
                            textAlign: "center",
                          }}
                        />
                      )}

                      {filter.selectedOptions &&
                        filter.selectedOptions.map((selectValue) => (
                          <Chip
                            label={selectValue}
                            onDelete={() =>
                              this.handleDeleteFilterSelection(
                                selectValue,
                                index
                              )
                            }
                            deleteIcon={
                              <CancelOutlinedIcon style={{ color: "white" }} />
                            }
                            style={{
                              backgroundColor: "#197D1D",
                              color: "white",
                              margin: 5,
                            }}
                          />
                        ))}
                    </span>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ ...tableCellStyle.slim, width: 50 }}
                  >
                    {this.getVisibleOptions(
                      filter?.visibleOptions,
                      index,
                      filter
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };

  getPrograms = () => {
    const { sitesInfo } = this.state;
    this.setState({ isProgramsLoading: true }, async () => {
      const api = axios.create({
        baseURL: `${process.env.REACT_APP_LA_BASE_URL}v1/`,
        adapter: jsonpAdapter,
      });
      try {
        const filterPromises = [];
        forEach(sitesInfo, ({ siteId, apiKey }) => {
          filterPromises.push(
            api.get(
              `${process.env.REACT_APP_LA_BASE_URL}v1/sites/${siteId}/programs/current?la-api-key=${apiKey}`,
              { headers: { "la-api-key": apiKey } }
            )
          );
        });
        const res = await Promise.all(filterPromises);
        this.setState({ programs: res, isProgramsLoading: false });
      } catch (error) {
        console.error("error-A", error);
        this.setState({
          isProgramsLoading: false,
          apiError: "Unable to fetch data against given API Key.",
        });
      }
    });
  };

  setSelectedProgram = (data, type) => {
    const widgetKey = type === "team" ? "teamWidget" : "scheduleWidget";
    this.setState({ [widgetKey]: data });
  };

  render() {
    const {
      match: { params },
    } = this.props;

    const {
      activeIndex,
      isLoading,
      openDialog,
      uId,
      codeSnippet,
      listingName,
      sitesInfo,
    } = this.state;
    return isLoading ? (
      <Loading />
    ) : (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{
              color: "#000000",
              fontFamily: "Montserrat Medium",
              fontSize: 24,
            }}
          >
            {uId ? listingName : "Create Listing"}
          </Typography>
          <div>
            {uId && (
              <>
                <AppButton
                  label="Preview"
                  classname="view-btn"
                  style={{
                    width: 140,
                    height: 27,
                    borderRadius: 25,
                    marginRight: 5,
                    boxSizing: "border-box",
                    border: "1px solid #197D1D",
                  }}
                  labelStyle={buttonGreenLabelStyle}
                  handler={() =>
                    window.open(
                      `${process.env.REACT_APP_WIDGET_URL}?id=${uId}`,
                      "_blank"
                    )
                  }
                />
                {![4, 5].includes(activeIndex) && (
                  <AppButton
                    label="View code"
                    classname="sign-in-btn"
                    style={{ width: 140, height: 27, borderRadius: 25 }}
                    handler={() => this.setViewCodeOpen(true)}
                    labelStyle={buttonStyle}
                  />
                )}
              </>
            )}
            <AppButton
              label="Save"
              classname="sign-in-btn"
              style={{ width: 140, height: 27, borderRadius: 25 }}
              labelStyle={buttonStyle}
              handler={uId ? this.updateWidget : this.saveWidget}
            />
          </div>
        </div>
        <Tabs
          value={activeIndex}
          onChange={this.handleTabChange}
          indicatorColor="black"
          textColor="#197D1D"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{ style: { background: "gray", color: "red" } }}
        >
          <Tab label="Site info" style={this.getStyle(activeIndex === 0)} />
          <Tab
            label="Columns"
            {...a11yProps(1)}
            style={this.getStyle(activeIndex === 1)}
          />
          <Tab
            label="Filters"
            {...a11yProps(2)}
            style={this.getStyle(activeIndex === 2)}
            onClick={this.fetchFiltersOptions}
          />
          <Tab
            label="Settings"
            {...a11yProps(4)}
            style={this.getStyle(activeIndex === 3)}
          />
          <Tab
            label="Schedule"
            {...a11yProps(4)}
            style={this.getStyle(activeIndex === 4)}
            onClick={this.getPrograms}
          />
          <Tab
            label="Teams"
            {...a11yProps(4)}
            style={this.getStyle(activeIndex === 5)}
            onClick={this.getPrograms}
          />
        </Tabs>
        <hr
          style={{
            padding: 0,
            margin: 0,
            color: "#C8C8C8",
          }}
        />

        <TabPanel value={activeIndex} index={0} style={tabStyle.tabStylePanel}>
          {this.getSiteInfoTab()}
        </TabPanel>
        <TabPanel value={activeIndex} index={1} style={{ width: "50%" }}>
          <DndProvider backend={Backend}>{this.getColumnsTab()}</DndProvider>
        </TabPanel>
        <TabPanel value={activeIndex} index={2} style={tabStyle.tabStylePanel}>
          <DndProvider backend={Backend}>{this.getFiltersTab()}</DndProvider>
        </TabPanel>
        <TabPanel value={activeIndex} index={3} style={tabStyle.tabStylePanel}>
          {this.getSettingsTab()}
        </TabPanel>
        <TabPanel value={activeIndex} index={4} style={tabStyle.tabStylePanel}>
          {/* schedule tab content */}
          {this.state.isProgramsLoading && <Loading />}

          <ScheduleWidget
            sitesInfo={sitesInfo}
            programs={this.state.programs}
            setViewCodeOpen={this.setViewCodeOpen}
            setCodeContent={this.setCodeContent}
            widgetType="schedule"
            widgetId={params.id}
            selectedProgram={this.state.scheduleWidget}
            setSelectedProgram={this.setSelectedProgram}
            isEdit={uId}
            isProgramsLoading={this.state.isProgramsLoading}
          />
        </TabPanel>
        <TabPanel value={activeIndex} index={5} style={tabStyle.tabStylePanel}>
          {/* teams tab content */}
          {this.state.isProgramsLoading && <Loading />}

          <ScheduleWidget
            sitesInfo={sitesInfo}
            programs={this.state.programs}
            setViewCodeOpen={this.setViewCodeOpen}
            setCodeContent={this.setCodeContent}
            widgetType="team"
            widgetId={params.id}
            selectedProgram={this.state.teamWidget}
            setSelectedProgram={this.setSelectedProgram}
            isEdit={uId}
            isProgramsLoading={this.state.isProgramsLoading}
          />
        </TabPanel>

        <CustomizedDialog
          open={openDialog}
          code=""
          handleClose={() => this.setViewCodeOpen(false)}
          content={codeSnippet}
        />
      </>
    );
  }
}
const CreateWidget = compose(withRouter, withFirebase)(CreateWidgetBase);
export default CreateWidget;
