import React from 'react';
import { Fab } from '@material-ui/core';

const AppButton = ({ label, handler, classname = '', style, labelStyle, ...props }) => {
  const getLabel = (label) => {
    return labelStyle ? <span style={labelStyle} > {label}</span> : <span>{label}</span>
  }
  return (
    <Fab className={`la-button ${classname}`} onClick={handler} {...props} style={{ ...style, textTransform: 'none' }}>
      {getLabel(label)}
    </Fab>
  );
}

export default AppButton;