import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { withRouter } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";

import * as _ from "lodash";
import { compose } from "recompose";
import faker from 'faker';
import { buttonStyle, buttonGreenLabelStyle } from '../../constants/admin';

import CustomizedDialog from "../CommonComponents/ViewCodeDialog";
import { withFirebase } from "../Firebase";
import AppButton from "../CommonComponents/AppButton";
import Loading from "../CommonComponents/Loading";
import DeleteConfirmationBox from "../CommonComponents/DeleteConfirmationBox";
import { FIREBASAE_COLLECTION, LISTING_HEADERS } from "../../constants/admin";
import { FixExistingCode, generateCodeSnippet} from '../../Helpers';

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto"
  }
}));


function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {_.map(LISTING_HEADERS, (headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            style={index === 0 ? {
              fontFamily: "Montserrat SemiBold",
              fontSize: 12,
              color: '#000000',
              background: '#F8F8FA',
              width: '20%'

            } : {
                color: "#A7A7A7", fontFamily: "Montserrat SemiBold",
                fontSize: 12,
                background: '#F8F8FA',
                width: index === 1 ? '20%': ''

              }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/**
 * Widget List Table
 * Material UI table component used to display paginated list of widgets
 * 
 */

function WidgetsTable({ firebase, history, widgetFilter, selectedOrganization }) {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [codeSnippet, setCodeSnippet] = React.useState('');
  const [widgets, setWidgets] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [deleteRow, setDeleteRow] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const fetchIdRef = React.useRef(0);

  // FixExistingCode(firebase);

  const fetchData = React.useCallback(async (filterSetting) => {
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      setLoading(true);
      setLoading(true);
      let query = '';
      if (filterSetting.selectedOrganization !== 'all' && filterSetting.widgetFilter !== "all") {
        query = firebase.database
          .collection(FIREBASAE_COLLECTION.widgets)
          .where("siteName", "==", filterSetting.widgetFilter)
          .where("orgAccountName", "==", filterSetting.selectedOrganization);
      } else if (filterSetting.selectedOrganization !== 'all') {
        query = firebase.database
          .collection(FIREBASAE_COLLECTION.widgets)
          .where("orgAccountName", "==", filterSetting.selectedOrganization);
      } else if (filterSetting.widgetFilter !== "all") {
        query = firebase.database
          .collection(FIREBASAE_COLLECTION.widgets)
          .where("siteName", "==", filterSetting.widgetFilter);
      } else {
        query = firebase.database.collection(FIREBASAE_COLLECTION.widgets)
          .orderBy("orgAccountName")
          // .startAt(filterSetting.page)
          // .limit(filterSetting.rowsPerPage);;
      }

      const snapshot = await query.get();
      const widgets = snapshot.docs.map(doc => doc.data());
      const _orgAccountName = widget => widget.orgAccountName.toLowerCase();
      const _siteName = widget => widget.siteName.toLowerCase();
      const _listingName = widget => widget.listingName.toLowerCase();
      const _widgets = _.sortBy(widgets, [_orgAccountName, _siteName, _listingName]);
      setWidgets(_widgets);
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData({ widgetFilter, selectedOrganization, page, rowsPerPage });
  }, [firebase.database, widgetFilter, selectedOrganization, page, rowsPerPage, fetchData]);

  const openCodeDialog = (row, status) => {
    setCodeSnippet(generateCodeSnippet(row.uId));
    setOpenDialog(true);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteListing = async row => {
    try {
      // setLoading(true);
      const selectedWidget = await firebase.database
        .collection(FIREBASAE_COLLECTION.widgets)
        .where("uId", "==", row.uId)
        .get();
      let deleteDocId = "";
      selectedWidget.forEach(doc => {
        deleteDocId = doc.id;
      });

      await firebase.database
        .collection(FIREBASAE_COLLECTION.widgets)
        .doc(deleteDocId)
        .delete();
      // setLoading(false);
      setOpenDeleteDialog(false)
      fetchData({ widgetFilter, selectedOrganization, page, rowsPerPage });
    } catch (error) {
      console.error("could not delete record.", error);
    }
  };

  /**
   * Copy functionality for existing widgets
   *  "-copy[Index Number]" is added to listing name to maintain uniqueness. 
   *  */ 

  const duplicateCurrentRecord = async (currentRecord) => {
    try {
      const randomId = faker.random.uuid();
      const { uId } = currentRecord;
      const selectedWidget = await firebase.database
        .collection(FIREBASAE_COLLECTION.widgets)
        .where("uId", "==", uId)
        .get();
      let updateDocId = "";
      selectedWidget.forEach(doc => {
        updateDocId = doc.id;
      });

      const recordTobeAdded = currentRecord;
      let _copyIndex = currentRecord.copyIndex;
      let _isFirstCopy = currentRecord.isFirstCopy;

      if (_copyIndex < 0) {
        // recordTobeAdded.siteName = `${recordTobeAdded.siteName}-copy`;
        recordTobeAdded.listingName = `${recordTobeAdded.listingName}-copy`;
        recordTobeAdded.copyIndex = -1;
        recordTobeAdded.isFirstCopy = true;
        _copyIndex = currentRecord.copyIndex + 1;

      } else {
        _copyIndex = currentRecord.copyIndex + 1;
        const _listName = recordTobeAdded.listingName.split('-copy');
        if (recordTobeAdded.listingName.includes('-copy(') || _isFirstCopy) {
          recordTobeAdded.listingName = `${recordTobeAdded.listingName}-copy(${_copyIndex})`;
          recordTobeAdded.copyIndex = -1;
        } else {
          recordTobeAdded.listingName = `${_listName[0]}-copy(${_copyIndex})`;
          recordTobeAdded.copyIndex = -1;
        }
      }

      recordTobeAdded.uId = randomId;
      recordTobeAdded.codeSnippet = generateCodeSnippet(randomId);
      setLoading(true);
      await firebase.database.collection(FIREBASAE_COLLECTION.widgets).add(recordTobeAdded);
      await firebase.database.collection(FIREBASAE_COLLECTION.widgets).doc(updateDocId).update({
        copyIndex: _copyIndex
      });
      history.push(`edit-widget/${randomId}`);
    } catch (error) {
      console.error('error', error);
      setLoading(false);
    }
  }

  const emptyRows = widgets.length === 0;
  return isLoading ? (
    <Loading />
  ) : (
      <div>
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            // stickyHeader
            >
              <EnhancedTableHead classes={classes} rowCount={widgets.length} />

              <TableBody key="listings">
                { widgets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                 .map((row, index)  => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const { siteName, listingName, siteId, orgAccountName, uId } = row;
                  return (
                    <TableRow hover key={`${siteId}-${siteName}`}>
                      <TableCell id={labelId} align="left" style={{
                        color: '#000000', fontFamily: 'Montserrat', fontSize: 12
                      }}>
                        {orgAccountName}
                      </TableCell>
                      <TableCell id={labelId} align="left" style={{
                        color: '#000000', fontFamily: 'Montserrat', fontSize: 12
                      }}>
                        {siteName}  
                    </TableCell>
                      <TableCell align="left" style={{
                        color: '#000000', fontFamily: 'Montserrat', fontSize: 12
                      }}>
                        {" "}
                        <span
                          style={{ color: "#1D5103", cursor: "pointer", textDecoration: 'underline', }}
                          onClick={() => history.push(`edit-widget/${uId}`)}
                        >
                          <strong>{listingName}</strong>
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <AppButton
                          label="Preview"
                          classname="view-btn"
                          style={{
                            width: 140,
                            height: 27,
                            borderRadius: 25,
                            marginRight: 5,
                            // background: '#FFFFFF',
                            boxSizing: 'border-box', border: '1px solid #197D1D',
                            // borderRadius: 17
                          }}
                          labelStyle={buttonGreenLabelStyle}
                          handler={() => window.open(`${process.env.REACT_APP_WIDGET_URL}?id=${uId}`, "_blank")}
                        />
                        <AppButton
                          label="View code"
                          classname="sign-in-btn"
                          style={{ width: 140, height: 27, borderRadius: 25 }}
                          handler={() => openCodeDialog(row)}
                          labelStyle={buttonStyle}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FileCopyOutlinedIcon
                          onClick={() => duplicateCurrentRecord(row)}
                          style={{ color: "#197D1D", cursor: "pointer" }}
                        />{" "}
                        {" "}
                        <DeleteOutlineOutlinedIcon
                          onClick={() => { setDeleteRow(row); setOpenDeleteDialog(true) }}
                          style={{ color: "#197D1D", cursor: "pointer" }}
                        />

                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} >No listings found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          {/* pagination */}
          <TablePagination
            rowsPerPageOptions={[100, 150, 200]}
            component="div"
            count={widgets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {/* Confimation modal for widget deletion */}
          <DeleteConfirmationBox
            open={openDeleteDialog}
            handleDelete={deleteListing}
            row={deleteRow}
            handleClose={() => setOpenDeleteDialog(false)}
            />

          {/* Copy Code modal */}
          <CustomizedDialog
            open={openDialog}
            code=""
            handleClose={() => setOpenDialog(false)}
            content={codeSnippet}
          />
        </Paper>
      </div>
    );
}
export default compose(withRouter, withFirebase)(WidgetsTable);
