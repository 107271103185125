import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppButton from '../CommonComponents/AppButton';
import { buttonStyle } from '../../constants/admin'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, setIsCopied, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
        setIsCopied(false);
        onClose()
      }}>
        <CloseIcon style={{ color: 'white' }} />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 'auto',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
}))(MuiDialogActions);

/**
 * Custom Dialog component for copying generate code snippet for a widget 
 */
export default function CustomizedDialog({ handleClose, open, content }) {
  const [isCopied, setIsCopied] = React.useState(false)
  const copyToClipBoard = () => {
    const el = textArea
    el.select()
    document.execCommand("copy")
    setIsCopied(true);
  }

  const handleCloseModal = () => {
    setIsCopied(false);
    handleClose();
  }
  let textArea;
  return (
    <div>
      <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{
          color: '#FFFFFF',	fontFamily: "Montserrat Medium",	fontSize: 21
        }} id="customized-dialog-title" onClose={handleClose} className='dialog-title' setIsCopied= {setIsCopied}>
          Code
        </DialogTitle>
        <DialogContent dividers>
          <div  id='my-code' style={{ border: '1px #C8C8C8 solid', padding: 8 }}>  <Typography gutterBottom>
            {content}
          </Typography>
            <textarea
              ref={(textarea) => textArea = textarea}
              value={content}
              style={{ position: 'absolute', left: '-999em' }}
            />

          </div>
        </DialogContent>
        <DialogActions>
          <AppButton label='Copy code'  labelStyle={buttonStyle} classname='sign-in-btn' style={{ width: 140, height: 27, borderRadius: 25 }} handler={copyToClipBoard} />
          {isCopied && <span style={{color: '#000000', fontFamily: 'Montserrat',	fontSize: 12, marginTop: 8}} >Code has been copied!</span>}
        </DialogActions>
      </Dialog>
    </div>
  );
}