import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { Avatar, CssBaseline, CircularProgress, FormControl, Paper, Typography } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";

import AppButton from '../CommonComponents/AppButton';
import Loading from '../CommonComponents/Loading';
import { buttonStyle } from '../../constants/admin';

const styles = theme => ({
  error: {
    color: 'red'
  },
  loader: {
    marginLeft: 5,
    color: 'white'
  },
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    // marginTop: theme.spacing.unit * 20,
    // width: '21%',
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    position: 'absolute',
    top: '35%',
    left: '50%',
    '-moz-transform': 'translateX(-50%) translateY(-50%)',
    '-webkit-transform': 'translateX(-50%) translateY(-50%)',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    width: 96,
    borderRadius: 32,
    backgroundColor: '#197D1D'
  }
});
const inputStyles = { 
  padding: 8, marginTop: 8, boxSizing: 'border-box',	height: 34,	width: '100%',	border: '1px solid #C8C8C8',	borderRadius: 3
};
const labelStyles = { margin: 0, height: '15px', width: '60px', color: '#000000', fontFamily: "Montserrat Medium", fontSize: '12px', lineHeight: '15px' }
const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  isLoading: false
};
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  /**
   * login submit method 
   *  */ 
  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ isLoading: true }, async () => {
      try {
        await this.props.firebase.doSignInWithEmailAndPassword(email, password);
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.WIDGETS);
      } catch (error) {
        this.setState({ error });
      }
      this.setState({ isLoading: false });
    });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error, isLoading } = this.state;
    const { classes } = this.props;

    return (
      isLoading ? <Loading /> : <div>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ height: 30, width: 84, color: '#000000', fontFamily: "Montserrat Medium", fontSize: 24 }}>
            Sign in
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <span style={labelStyles}>Username</span>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={this.onChange}
                style={inputStyles}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <span style={labelStyles}>Password</span>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={this.onChange}
                style={inputStyles}
              />
            </FormControl>
            {error && <p className={classes.error}>{error.message}</p>}
            
            <center>
              <AppButton label='Sign in' classname='sign-in-btn'
                labelStyle={buttonStyle}
                style={{ marginTop: 10, width: 140, height: 27, borderRadius: 25, }} handler={this.onSubmit} />
            </center>

            {isLoading && <CircularProgress disableShrink size={18} className={classes.loader} />}

          </form>
        </Paper>
      </div>
    );
  }
}
const SignInForm = withStyles(styles)(
  compose(withRouter, withFirebase)(SignInFormBase)
);
export default SignInForm;
