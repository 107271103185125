import app from 'firebase/app';
import 'firebase/auth';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_API_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


class Firebase {
    constructor() {
        // app.initializeApp(config);
        app.initializeApp(config);
        this.auth = app.auth();
        this.database =  app.firestore();
        // this.database.useEmulator('localhost', 8080);
        // app.functions().useEmulator('localhost', 5001);
        // app.storage().useEmulator('localhost', 9199);
    }
    //   doCreateUserWithEmailAndPassword = (email, password) =>
    //     this.auth.createUserWithEmailAndPassword(email, password);
    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);
    
    doSignOut = () => this.auth.signOut();
}

export default Firebase;