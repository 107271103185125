/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Radio,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import jsonpAdapter from "axios-jsonp";
import AppButton from "../../CommonComponents/AppButton";
import { forEach, isEmpty, isNil } from "lodash";
import { buttonStyle } from "../../../constants/admin";
import { generateScheduleCodeSnippet } from "../../../Helpers";
import Loading from "../../CommonComponents/Loading";

const styles = {
  filterButton: {
    backgroundColor: "transparent",
    fontSize: "12px",
    color: "#228B22",
    boxShadow: "none",
    textTransform: "none",
    width: "auto",
    minWidth: "120px",
    minHeight: "15px",
    height: "auto",
    justifyContent: "space-around",
    borderRadius: "20px",
    border: "1px solid #228B22",
    padding: "5px",
    marginRight: "5px",
    textAlign: "center",
  },
  filterOptions: {
    borderColor: "#228B22",
    borderRadius: "20px",
    minWidth: "200px",
    width: "auto",
    textAlign: "center",
  },
  inputStyles: {
    fontSize: 14,
    padding: 8,
    marginTop: 8,
    boxSizing: "border-box",
    height: 34,
    width: 275,
    border: "1px solid #C8C8C8",
    borderRadius: 3,
    textAlign: "center",
  },
};

const ScheduleWidget = ({
  sitesInfo,
  programs,
  setViewCodeOpen,
  setCodeContent,
  widgetType,
  widgetId,
  selectedProgram,
  setSelectedProgram,
  isEdit,
  isProgramsLoading,
}) => {
  const tableCellStyle = {
    slim: { padding: 0 },
  };
  const [programTeams, setProgramTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleProgramSelection = ({ target: { value } }, siteId, teamId) => {
    setSelectedProgram({ programId: value, siteId, teamId }, widgetType);
  };
  const [apiKey] = sitesInfo.filter(
    (site) => site.siteId === selectedProgram.siteId
  );
  const isDisabled =
    isEmpty(isEdit) ||
    isEmpty(selectedProgram.programId) ||
    (isNil(selectedTeam?.value) && widgetType !== "team");

  useEffect(() => {
    if (widgetType !== "team") {
      getProgramTeams(selectedProgram.programId, selectedProgram.siteId);
    }
  }, [selectedProgram.programId, selectedProgram.siteId, widgetType]);

  const getProgramTeams = async (programId, siteId) => {
    setIsLoading(true);
    const api = axios.create({
      baseURL: `${process.env.REACT_APP_LA_BASE_URL}v1/`,
      adapter: jsonpAdapter,
    });
    try {
      let filterPromises = api.get(
        `${process.env.REACT_APP_LA_BASE_URL}v1/sites/${siteId}/programs/${programId}/teams?x-api-key=${apiKey.apiKey}`
      );

      const { data } = await Promise.resolve(filterPromises);
      setProgramTeams(data);
      if (selectedProgram.teamId) {
        const [team] = data.filter(
          (teams) => teams.teamId === selectedProgram.teamId
        );
        if (!isEmpty(team)) {
          setSelectedTeam({ value: team.teamId, name: team.teamName });
        } else {
          setSelectedTeam({ value: data[0]?.teamId, name: data[0]?.teamName });
        }
      } else {
        setSelectedTeam({ value: data[0]?.teamId, name: data[0]?.teamName });
        setSelectedProgram({ ...selectedProgram, teamId: data[0]?.teamId });
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getScheduleWidgetCode = (value, siteId) => {
    setCodeContent(
      generateScheduleCodeSnippet({
        widgetId: widgetId,
        siteId: selectedProgram.siteId,
        programId: selectedProgram.programId,
        teamId: selectedTeam?.value,
        widgetType: widgetType,
      })
    );
    setViewCodeOpen(value);
  };
  const renderTeamsDropdown = () => {
    if (isEmpty(programTeams)) {
      return (
        <span>No Teams for this program. Please select another program.</span>
      );
    } else {
      return (
        <Select
          id="team-filter"
          defaultValue={selectedTeam?.name}
          value={selectedTeam?.value}
          name={selectedTeam?.name}
          onChange={(team) => handleFilterOptionChange(team)}
          key="teams-filter"
          style={styles.filterButton}
          disableUnderline={true}
          floatPlaceholder="never"
        >
          {programTeams.map((team) => (
            <MenuItem
              value={team.teamId}
              key={team.teamName}
              name={team.teamName}
            >
              <span> {team.teamName}</span>
            </MenuItem>
          ))}
        </Select>
      );
    }
  };

  const handleFilterOptionChange = (team) => {
    setSelectedProgram({ ...selectedProgram, teamId: team.target.value });
    setSelectedTeam(team.target);
  };

  const renderTeamSelection = () => {
    if (!isNil(programTeams)) {
      return isLoading ? (
        <Loading size={28} left="5%" position="relative" />
      ) : (
        renderTeamsDropdown()
      );
    }
  };

  const renderProgramsList = () => {
    let body = [];
    if (isEmpty(programs) && !sitesInfo) {
      return null;
    } else {
      forEach(programs, (_programs, index) => {
        const siteId = sitesInfo[index].siteId;
        body.push(
          _programs.data
            .filter(
              (program) =>
                program.visibility === "Public" &&
                program.type !== "CLASS" &&
                (program.state === "LIVE" || program.state === "UPCOMING")
            )
            .map((prog) => {
              return (
                <TableRow key={prog.programId}>
                  <TableCell style={{ ...tableCellStyle.slim }}>
                    <Radio
                      checked={
                        parseInt(selectedProgram.programId) === prog.programId
                      }
                      key={`sort-${prog.programId}`}
                      style={{ color: "#197D1D" }}
                      onChange={(value) =>
                        handleProgramSelection(
                          value,
                          siteId,
                          selectedTeam.value
                        )
                      }
                      value={prog.programId}
                    />
                  </TableCell>
                  <TableCell align="left" style={{ ...tableCellStyle.slim }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "30px",
                        alignItems: "baseline",
                      }}
                    >
                      {prog.name}
                      {parseInt(selectedProgram.programId) === prog.programId &&
                        widgetType !== "team" &&
                        renderTeamSelection()}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })
        );
      });
    }
    return body;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="h6"
          variant="h5"
          style={{
            color: "#000000",
            fontFamily: "Montserrat Medium",
            fontSize: 14,
          }}
        >
          Select a program and a team respectively to configure the schedule
          widget.
        </Typography>
        <AppButton
          disabled={isDisabled}
          label="View code"
          classname={`${
            isDisabled ? "disabled-btn" : "sign-in-btn schedule-code-btn"
          }`}
          style={{ width: 140, height: 27, borderRadius: 25 }}
          handler={() =>
            getScheduleWidgetCode(
              true,
              selectedProgram.siteId,
              selectedProgram.programId,
              selectedTeam?.value,
              widgetType
            )
          }
          labelStyle={buttonStyle}
        />
      </div>

      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={tableCellStyle.slim}></TableCell>
            <TableCell
              style={{
                padding: 0,
                color: "#197D1D",
                fontFamily: "Montserrat SemiBold",
                fontSize: 12,
              }}
            >
              Program Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderProgramsList()}</TableBody>
      </Table>
      {(!isEdit || isEmpty(programs)) && !isProgramsLoading && (
        <h3>No programs found.</h3>
      )}
    </>
  );
};

export default ScheduleWidget;
