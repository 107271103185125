import { compact, uniq, map, join, find, cloneDeep, remove } from "lodash";
import { filterConfig, DAYS, PROGRAM_STATES, AGE } from "../constants/admin";

export const getFilterOptions = (
  programs,
  sitesInfo,
  firstDayOfTheWeek = "Monday"
) => {
  const getMasterSubProgramValue = () => {
    const subPrograms = [];
    for (const id in programs) {
      const masterId = programs[id].masterProgramId;
      const name = programs[id].name;
      if (masterId > 0) {
        subPrograms.push(name);
      }
    }
    const _subPrograms =
      subPrograms &&
      subPrograms.length > 0 &&
      subPrograms.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      );
    if (!_subPrograms) {
      return [];
    }
    return [...new Set(_subPrograms)];
  };

  const getFilterValues = (filterKey) => {
    const _values = compact(uniq(map(programs, filterKey)));
    _values && _values.length > 0 && _values.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    return _values;
  };

  const siteNames = map(sitesInfo, "siteName");
  const filterObj = {};
  filterConfig.map(({ id }) => {
    filterObj[id] = { isOpen: false, values: [] };
    if (id === "registrationType") {
      filterObj[id].values = ["Full Team", "Individual"];
    } else if (id === "scheduleDays") {
      const days =
        firstDayOfTheWeek === "Monday" ? DAYS : uniq([DAYS[6], ...DAYS]);
      filterObj[id].values = days;
    } else if (id === "state") {
      filterObj[id].values = PROGRAM_STATES;
    } else if (id === "age") {
      filterObj[id].values = AGE;
    } else if (id === "siteName") {
      filterObj[id].values = siteNames;
    } else if (id === "subProgram") {
      filterObj[id].values = getMasterSubProgramValue();
    } else {
      filterObj[id].values = getFilterValues(id).map((value) => value);
    }
  });
  return filterObj;
};

export const generateCodeSnippet = (id) => {
  return `
    <div id="la-listing-widget-${id}"></div>
    <script src="${process.env.REACT_APP_WIDGET_URL}iframe-updater-v1.js" type="text/javascript"></script>
		<script>generateLeagueWidget('${id}', '${process.env.REACT_APP_WIDGET_URL}');</script>
    `;
};

export const generateScheduleCodeSnippet = ({
  teamId,
  widgetId,
  siteId,
  programId,
  widgetType,
}) => {
  return `
    <div id="la-schedule-widget-${widgetId}-${programId}"></div>
    <script src="${process.env.REACT_APP_WIDGET_URL}iframe-updater-v2.js" type="text/javascript"></script>
		<script>generateScheduleWidget('${widgetId}', '${process.env.REACT_APP_WIDGET_URL}', ${programId},${teamId}, ${siteId}, '${widgetType}');</script>`;
};

export const getSiteNameForListing = (_sitesInfo, selectedSiteId, siteName) => {
  let _siteName = siteName;
  let selectedSiteName = "";
  const sitesInfo = cloneDeep(_sitesInfo);

  if (sitesInfo) {
    let siteNames = [];
    const seletedSite = find(sitesInfo, { siteId: selectedSiteId });
    if (seletedSite) {
      selectedSiteName = seletedSite.siteName;
      remove(sitesInfo, { siteId: selectedSiteId });
      siteNames = map(sitesInfo, "siteName");
      _siteName = join(siteNames, " + ");
    } else {
      const firstSite = sitesInfo.shift();
      siteNames = map(sitesInfo, "siteName");
      _siteName = join(siteNames, " + ");
      selectedSiteName = sitesInfo && firstSite.siteName;
    }
  }
  return {
    selectedSiteName,
    siteName: _siteName,
  };
};

// Script to FIX cols Issue

export const FixExistingCode = async (firebase) => {
  const snapshot = await firebase.database.collection("widgets").get();
  const selectedWidgets = [];
  snapshot.docs.forEach((doc) => {
    const data = doc.data();
    selectedWidgets.push({ id: doc.id, ...data });
  });

  selectedWidgets.forEach(async (widget) => {
    // Make site Select
    // if (!widget.sitesInfo) {
    //   widget.sitesInfo = [{
    //     siteId: widget.siteId,
    //     apiKey: widget.apiKey,
    //     siteName: widget.siteName
    //   }];
    // }

    // Default Selected Site Id
    // if (!widget.selectedSiteId) {
    //   widget.selectedSiteId = widget.sitesInfo && widget.sitesInfo[0].siteId;
    // }

    // Default show yes for  showProcessingFee  and  displayPriceLabels

    // if (!widget.showProcessingFee) {
    //   widget.showProcessingFee = 'yes';
    // }

    // if (!widget.showScheduleStandingLinks) {
    //   widget.showScheduleStandingLinks = 'yes';
    // }

    // if (!widget.displayPriceLabels) {
    //   widget.displayPriceLabels = 'yes';
    // }

    // format site name
    // let _siteName = '';
    // if (widget.sitesInfo) {
    //   const siteNames = getSiteNameForListing(widget.sitesInfo, widget.selectedSiteId, widget.siteName);
    //   _siteName = siteNames.selectedSiteName + (siteNames.siteName && siteNames.siteName.length > 0 ? ` + ${siteNames.siteName}` : '');
    // }
    console.log("updating widget");
    await firebase.database.collection('widgets').doc(widget.id).update({
      // siteName: _siteName,
      // displayPriceLabels: widget.displayPriceLabels,
      // showProcessingFee: widget.showProcessingFee,
      // selectedSiteId: widget.selectedSiteId,
      // sitesInfo: widget.sitesInfo,
      // showScheduleStandingLinks: widget.showScheduleStandingLinks,
      
      //migration for program state update
      programStateLabels: {
        OPEN_REGISTRATIONS: widget.programStateLabels['OPEN_FOR_REGISTRATIONS'] || widget.programStateLabels['OPEN_REGISTRATIONS'] || 'Open for Registrations',
        OPEN_REGISTRATIONS_AND_UPCOMING: widget.programStateLabels['OPEN_FOR_REGISTRATIONS_AND_UPCOMING'] || widget.programStateLabels['OPEN_REGISTRATIONS_AND_UPCOMING'] || 'Open for Registrations and Upcoming',
        LIVE: widget.programStateLabels['LIVE'] || 'Live',
        UPCOMING: widget.programStateLabels['UPCOMING'] || 'Upcoming'
      },
      filtersConfig : widget.filtersConfig.map(filter => { 
        if(filter.id === 'state'){
          filter.selectedOptions = filter.selectedOptions.map(option => option.replace('Open Registrations', "Open for Registrations and Upcoming"));
          filter.visibleOptions = (filter.visibleOptions || []).map(option => option.replace('Open Registrations', "Open for Registrations and Upcoming"));
        } 
        return filter;
    })
    });
  });
};
