import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, Select } from "@material-ui/core";
import * as _ from 'lodash';
import WidgetsTable from './WidgetsTable';
import AppButton from '../CommonComponents/AppButton';
import { withFirebase } from "../Firebase";
import { FIREBASAE_COLLECTION } from '../../constants/admin';
import { buttonStyle } from '../../constants/admin';

const styles = {
  filterButton: {
    backgroundColor: 'transparent',
    fontSize: '12px',
    color: '#228B22',
    boxShadow: 'none',
    textTransform: 'none',
    width: 'auto',
    minWidth: '120px',
    minHeight: '25px',
    height: 'auto',
    justifyContent: 'space-around',
    borderRadius: '20px',
    border: '1px solid #228B22',
    padding: '5px',
    marginRight: '5px',
  },
  filterOptions: {
    borderColor: '#228B22',
    borderRadius: '20px',
    minWidth: '200px',
    width: 'auto'
  }
};
/**
 *  Landing page
 * 
 * Displays a list of widgets.
 * The list can be filtered by Organization and Site
 * */
function Widgets({ history, firebase }) {
  const [widgets, setWidgets] = useState([]);
  const [orgWidgets, setOrgWidgets] = useState([]);
  const [selectedWidget, setSelectedWidget] = useState('all');
  const [selectedOrganization, setSelectedOrganization] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      const query = await firebase.database.collection(FIREBASAE_COLLECTION.widgets);
      const snapshot = await query.get();
      const fetchedWidgtes = snapshot.docs.map(doc => {
        return {
          id: doc.data().uId,
          value: doc.data().siteId,
          ...doc.data()
        };
      });
      setOrgWidgets(_.sortBy(_.uniqBy(fetchedWidgtes, 'orgAccountName'), (w) => w.orgAccountName ? w.orgAccountName.toLowerCase() : ''));
      setWidgets(_.sortBy(_.uniqBy(fetchedWidgtes, 'siteName'), (w) => w.orgAccountName ? w.siteName.toLowerCase() : ''));
    };
    fetchData();
  }, []);

  const handleSiteChange = (event) => {
    setSelectedWidget(event.target.value)
  };

  const handleOrganizationChange = async (event) => {
    const selectedOrganization =  event.target.value;
    setSelectedOrganization(selectedOrganization)
    setSelectedWidget('all')
    let query = '' ;
    if(selectedOrganization !== 'all'){
      query = firebase.database.collection(FIREBASAE_COLLECTION.widgets).where("orgAccountName", "==", selectedOrganization);
    } else {
      query = firebase.database.collection(FIREBASAE_COLLECTION.widgets);
    }
    const snapshot = await query.get();
    const fetchedWidgtes = snapshot.docs.map(doc => {
      return {
        id: doc.data().uId,
        value: doc.data().siteId,
        ...doc.data()
      };
    });
    setWidgets(_.uniqBy(_.sortBy(fetchedWidgtes, (w) => {
      return  w.orgAccountName ? w.siteName.toLowerCase() : ''
     }), 'siteName'));

  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: 25 }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%'}}>
          <FormControl
            style={{
              width: '15%',
              marginBottom: 20
            }}
          >
            <Select
              id="org-filter"
              value={selectedOrganization}
              onChange={handleOrganizationChange}
              style={styles.filterButton}
              disableUnderline
            >
              <MenuItem value="all">
               Search by Organization
            </MenuItem>
              {_.map(orgWidgets, widget => <MenuItem style={styles.filterOptions} value={widget.orgAccountName}>{widget.orgAccountName}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl
            style={{
              width: '15%',
              marginBottom: 20
            }}
          >
            <Select
              id="sites-filter"
              value={selectedWidget}
              onChange={handleSiteChange}
              style={styles.filterButton}
              disableUnderline
            >
              <MenuItem value="all">
               Search by Site
            </MenuItem>
              {_.map(widgets, widget => <MenuItem style={styles.filterOptions} value={widget.siteName}>{widget.siteName}</MenuItem>)}
            </Select>
          </FormControl>
        </div>

        <AppButton labelStyle={buttonStyle} label='New Listings' classname='sign-in-btn' style={{ width: 140, height: 27, borderRadius: 25 }} handler={() => history.push('/create-widget')} />
      </div>
      {/* custom table component for listing widgets */}
      <WidgetsTable widgetFilter={selectedWidget} selectedOrganization={selectedOrganization} />
    </div>
  );
}

export default withFirebase(Widgets);