import { isEmpty } from "lodash";
import { STATES } from "./admin";

export const SIGNUP_ACTIONS = {
  viaWidget: {
    label: "View Detail via Widget",
    value: "viaWidget"
  },
  viaLeagueApps: {
    label: "View Details on LeagueApps",
    value: "viaLeagueApps"
  },
  viaDirectRegistration: {
    label: "Direct Register",
    value: "viaDirectRegistration"
  }, 
  none: {
    label: "None",
    value: ""
  },
}

export const formatStateLabels = (stateOptions) => {
  const _updatedStateOptions = {};

  Object.keys(stateOptions).map(state => {
    const option = stateOptions[state].trim();
    if(isEmpty(option)) {
      _updatedStateOptions[state] = STATES[state];
    } else {
      _updatedStateOptions[state] = option  
    }
  })
  return _updatedStateOptions;
}

export const BUTTON_TEXTS = {
  signUp: "Sign Up",
  soldOut: "Sold Out"
} 