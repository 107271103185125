import React, { Component, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";

import AppHeader from '../App/AppHeader';
import SignInPage from '../SignIn';
import Widgets from '../Widgets'
import CreateWidget from '../Widgets/CreateWidget'
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';

const AppBase = (props) => {
  const [authUser, setAuthUser] = useState(null)
  
  useEffect(() => {
    const listener = async() => {
      await props.firebase.auth.onAuthStateChanged(authUser => {
        setAuthUser(authUser ? authUser : null)
      });
    }

    listener()

    if(!authUser && pathName !== ROUTES.SIGN_IN && pathName !== ROUTES.HOME){
      return <Redirect to={ROUTES.SIGN_IN}/>
    }
  }, [])

  const pathName = props && props.history && props.history.location && props.history.location.pathname;

  return (
    <div className={(pathName === ROUTES.SIGN_IN || pathName === ROUTES.HOME) ? 'login-background' : ''} >
      {(pathName !== ROUTES.SIGN_IN && pathName !== ROUTES.HOME && authUser) && <AppHeader authUser={authUser} />}
      <Route exact path={ROUTES.HOME} component={SignInPage} />

      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.WIDGETS} component={Widgets} />
      <Route path={ROUTES.CREATE_WIDGET} component={CreateWidget} />
      <Route path={ROUTES.EDIT_WIDGET} component={CreateWidget} />
    </div>
  )
}

const App = compose(withRouter, withFirebase)(AppBase);
export default App