import { BUTTON_TEXTS } from "./widget";
export const COLUMNS = [
  {
    key: "showSport",
    label: "Sport",
    value: false,
    id: "sport",
    customLabel: "",
  },
  { key: "showName", label: "Name", value: true, id: "name", customLabel: "" },
  {
    key: "showLocation",
    label: "Location",
    value: true,
    id: "location",
    customLabel: "",
  },
  {
    key: "showDayofWeek",
    label: "Day of the Week",
    value: true,
    id: "scheduleDays",
    customLabel: "",
  },
  {
    key: "showTime",
    label: "Time",
    value: true,
    id: "scheduleTimes",
    customLabel: "",
  },
  {
    key: "showGender",
    label: "Gender",
    value: false,
    id: "gender",
    customLabel: "",
  },
  {
    key: "showLevel",
    label: "Level",
    value: true,
    id: "experienceLevel",
    customLabel: "",
  },
  {
    key: "showFormat",
    label: "Format",
    value: false,
    id: "leagueFormat",
    customLabel: "",
  },
  {
    key: "showTeamPrice",
    label: "Team Price",
    value: false,
    id: "teamFee",
    customLabel: "",
  },
  {
    key: "showNewTeamPrice",
    label: "New Team Price",
    value: false,
    id: "teamPrice",
    customLabel: "",
  },
  {
    key: "showTeamPlayerPrice",
    label: "Team Player Price",
    value: false,
    id: "teamIndividualFee",
    customLabel: "",
  },
  {
    key: "showIndividualPrice",
    label: "Individual Price",
    value: true,
    id: "freeAgentFee",
    customLabel: "",
  },
  {
    key: "showSeason",
    label: "Season",
    value: true,
    id: "season",
    customLabel: "",
  },
  {
    key: "showStartDate",
    label: "Start Date",
    value: true,
    id: "startTime",
    customLabel: "",
  },
  {
    key: "showSportIcon",
    label: "Logo",
    value: false,
    id: "programLogo150",
    customLabel: "",
  },
  {
    key: "showMaxTeamsCount",
    label: "Max Teams",
    value: false,
    id: "maxTeamCount",
    customLabel: "",
  },
  {
    key: "showMaxFreeAgentsCount",
    label: "Max Free Agents",
    value: false,
    id: "maxFreeAgentsCount",
    customLabel: "",
  },
  {
    key: "showMaxPlayersCount",
    label: "Max Players",
    value: false,
    id: "maxPlayersCount",
    customLabel: "",
  },
  {
    key: "showRemainingTeamCount",
    label: "Team Spots Remaining",
    value: false,
    id: "remainingTeamCount",
    customLabel: "",
  },
  {
    key: "showRemainingFreeAgents",
    label: "Free Agent Spots Remaining",
    value: false,
    id: "remainingFreeAgents",
    customLabel: "",
  },
  {
    key: "showRemainingPlayers",
    label: "Player Spots Remaining",
    value: false,
    id: "remainingPlayers",
    customLabel: "",
  },
  {
    key: "showRegistrationEndDate",
    label: "Registration End Date",
    value: false,
    id: "endRegistrationTime",
    customLabel: "",
  },
  {
    key: "showRegistrationStatus",
    label: "Registration Status",
    id: "registrationStatus",
    value: false,
    customLabel: "",
  },
  {
    key: "showSponsor",
    label: "Sponsor",
    id: "sponsor",
    value: false,
    customLabel: "",
  },
  {
    key: "showSport",
    label: "Sport",
    value: false,
    id: "sport",
    customLabel: "",
  },
  { key: "showName", label: "Name", value: true, id: "name", customLabel: "" },
  {
    key: "showLocation",
    label: "Location",
    value: true,
    id: "location",
    customLabel: "",
  },
  {
    key: "showDayofWeek",
    label: "Day of the Week",
    value: true,
    id: "scheduleDays",
    customLabel: "",
  },
  {
    key: "showTime",
    label: "Time",
    value: true,
    id: "scheduleTimes",
    customLabel: "",
  },
  {
    key: "showGender",
    label: "Gender",
    value: false,
    id: "gender",
    customLabel: "",
  },
  {
    key: "showLevel",
    label: "Level",
    value: true,
    id: "experienceLevel",
    customLabel: "",
  },
  {
    key: "showFormat",
    label: "Format",
    value: false,
    id: "leagueFormat",
    customLabel: "",
  },
  {
    key: "showTeamPrice",
    label: "Team Price",
    value: false,
    id: "teamFee",
    customLabel: "",
  },
  {
    key: "showNewTeamPrice",
    label: "New Team Price",
    value: false,
    id: "teamPrice",
    customLabel: "",
  },
  {
    key: "showTeamPlayerPrice",
    label: "Team Player Price",
    value: false,
    id: "teamIndividualFee",
    customLabel: "",
  },
  {
    key: "showIndividualPrice",
    label: "Individual Price",
    value: true,
    id: "freeAgentFee",
    customLabel: "",
  },
  {
    key: "showSeason",
    label: "Season",
    value: true,
    id: "season",
    customLabel: "",
  },
  {
    key: "showStartDate",
    label: "Start Date",
    value: true,
    id: "startTime",
    customLabel: "",
  },
  {
    key: "showSportIcon",
    label: "Logo",
    value: false,
    id: "programLogo150",
    customLabel: "",
  },
  {
    key: "showRegistrationEndDate",
    label: "Registration End Date",
    value: false,
    id: "endRegistrationTime",
    customLabel: "",
  },
  {
    key: "showRegistrationStatus",
    label: "Registration Status",
    id: "registrationStatus",
    value: false,
    customLabel: "",
  },
  {
    key: "showSponsor",
    label: "Sponsor",
    id: "sponsor",
    value: false,
    customLabel: "",
  },

  {
    key: "showProgramType",
    label: "Program Type",
    id: "type",
    value: false,
    customLabel: "",
  },
  {
    key: "showProgramCode",
    label: "Program Code",
    id: "code",
    value: false,
    customLabel: "",
  },
  {
    key: "showProgramState",
    label: "Program State",
    id: "state",
    value: false,
    customLabel: "",
  },
  { key: "showAge", label: "Age", id: "age", value: false, customLabel: "" },
  {
    key: "showSubProgram",
    label: "Sub Program",
    id: "subProgram",
    value: false,
    customLabel: "",
  },
  {
    key: "showSiteName",
    label: "Site",
    id: "siteName",
    value: false,
    customLabel: "",
  },
];

export const STATES = {
  LIVE: "Live",
  UPCOMING: "Upcoming",
  OPEN_REGISTRATIONS: "Open for Registrations",
  OPEN_REGISTRATIONS_AND_UPCOMING: "Open for Registrations and Upcoming",
};

export const FILTERS = [
  {
    key: "showState",
    label: "Program State",
    id: "state",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showSport",
    label: "Sport",
    id: "sport",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showDayofWeek",
    label: "Day of the Week",
    id: "scheduleDays",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showGender",
    label: "Gender",
    id: "gender",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showLevel",
    label: "Level",
    id: "experienceLevel",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showFormat",
    label: "Format",
    id: "leagueFormat",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showSeason",
    label: "Season",
    id: "season",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showLocation",
    label: "Location",
    id: "location",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showProgramType",
    label: "Program Type",
    id: "type",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showProgramCode",
    label: "Program Code",
    id: "code",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showRegistrationType",
    label: "Registration Type",
    id: "registrationType",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showAge",
    label: "Age",
    id: "age",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showSiteName",
    label: "Site",
    id: "siteName",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showSponsor",
    label: "Sponsor",
    id: "sponsor",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },
  {
    key: "showSubProgram",
    label: "Sub Program",
    id: "subProgram",
    value: false,
    visibility: false,
    selectedOptions: [],
    hasRelevancy: true,
    visibleOptions: [],
  },

  // { key: 'showScheduleandStandings', label: 'Schedule and Standings', id: 'Toggle', value: false, visibility: false, selectedOptions: [] },
];

export const filterConfig = [
  { id: "state", label: "State" },
  { id: "sport", label: "Sport" },
  { id: "scheduleDays", label: "Day Of The Week" },
  { id: "gender", label: "Gender" },
  { id: "experienceLevel", label: "Level" },
  { id: "leagueFormat", label: "Format" },
  { id: "season", label: "Season" },
  { id: "sponsor", label: "Sponsor" },
  { id: "location", label: "Location" },
  { id: "neighborhood", label: "Neighborhood" },
  { id: "type", label: "Program Type" },
  { id: "code", label: "Program Code" },
  { id: "registrationType", label: "Registration Type" },
  { id: "age", label: "Age" },
  { id: "siteName", label: "Site Name" },
  { id: "subProgram", label: "Sub Program" },
  { id: "state", label: "State" },
  { id: "sport", label: "Sport" },
  { id: "scheduleDays", label: "Day Of The Week" },
  { id: "gender", label: "Gender" },
  { id: "experienceLevel", label: "Level" },
  { id: "leagueFormat", label: "Format" },
  { id: "season", label: "Season" },
  { id: "sponsor", label: "Sponsor" },
  { id: "location", label: "Location" },
  { id: "neighborhood", label: "Neighborhood" },
  { id: "type", label: "Program Type" },
  { id: "code", label: "Program Code" },
  { id: "registrationType", label: "Registration Type" },
  { id: "age", label: "Age" },
  { id: "siteName", label: "Site Name" },
  { id: "subProgram", label: "Sub Program" },
  // { id: 'Toggle', label: 'Schedule and Standings' },
];

export const FIREBASAE_COLLECTION = {
  widgets: "widgets",
  neighborhoods: "neighborhoods",
};

export const PROGRAM_STATES = [
  "Live",
  "Open for Registrations",
  "Upcoming",
  "Open for Registrations and Upcoming",
];

export const AGE = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

export const LISTING_HEADERS = [
  { id: "orgAccountName", label: "Org Account Name" },
  { id: "siteId", label: "Site Name" },
  { id: "apiKey", label: "Listings Name" },
  {
    id: "action",
    label: "Actions",
  },
  {
    id: "ops",
    label: "",
  },
];

export const START_WEEK_FROM_MONDAY = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const START_WEEK_FROM_SUNDAY = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const WIDGET_INITIAL_STATE = {
  programs: null,
  openDialog: false,
  programId: 1020760,
  siteId: "",
  activeIndex: 0,
  apiKey: "",
  notes: "",
  newAddeSiteIndex: -1,
  siteName: "",
  defaultSort: "startTime",
  defaultSortDirection: "asc",
  listingName: undefined,
  orgAccountName: undefined,
  soldOutButtonText: BUTTON_TEXTS.soldOut,
  signUpButtonText: BUTTON_TEXTS.signUp,
  showZipCodeFinder: false,
  defaultZipCode: null,
  defaultZipCodeError: null,
  signUpAction: "viaLeagueApps",
  error: null,
  firstDayOfTheWeek: "Monday",
  listingNameError: null,
  siteNameError: null,
  apiKeyError: null,
  siteIdError: null,
  apiError: null,
  columns: COLUMNS,
  columnsError: null,
  filters: FILTERS,
  customNameEditIndex: -1,
  isLoading: false,
  isSelectAllFilters: false,
  isAllFiltersVisible: false,
  sitesInfo: [
    {
      siteId: undefined,
      apiKey: undefined,
      siteName: undefined,
    },
  ],
  siteInfoErrors: {
    siteId: { message: "Please enter site ID.", indexes: [] },
    apiKey: { message: "Please enter API key.", indexes: [] },
    siteName: { message: "Please enter site name.", indexes: [] },
  },
  selectedSiteId: undefined,
  showProcessingFee: "yes",
  displayPriceLabels: "yes",
  showScheduleStandingLinks: "yes",
  showHasPaymentPlans: "no",
  showAdditionalTeamFee: "no",
  programStateLabels: STATES,
  hoverIndex: -1,
  scheduleWidget: {
    programId: "",
    siteId: "",
    codeSnippet: null,
  },
  teamWidget: {
    programId: "",
    siteId: "",
    codeSnippet: null,
  },
};

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const buttonStyle = {
  color: "#FFFFFF",
  fontFamily: "Montserrat Medium",
  fontSize: 12,
  textAlign: "center",
};

export const buttonGreenLabelStyle = {
  color: "green",
  fontFamily: "Montserrat Medium",
  fontSize: 12,
  textAlign: "center",
};
